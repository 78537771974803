export const containerVariants = {
    hidden: {
        transition: {
            staggerChildren: 0.2,
        },
    },
    visible: {
        transition: {
            staggerChildren: 0.2,
        },
    },
};

export const childVariants = {
    hidden: {
        x: -100,
        opacity: 0,
    },
    visible: {
        x: 0,
        opacity: 1,
    },
};
