import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useIndexeDb } from "../../contexts/IndexedDdProvider";
import { useLocale } from "../../contexts/LocaleProvider";
import { useErrorPageQuery } from "../../generated";
import { updateSingleTypeData } from "../../lib/indexed_db/indexed_db";
import Loading from "../Loading/Loading";

export default function ErrorPage() {
    const { locale, slugsEN, slugsPT } = useLocale();
    const { data, error } = useErrorPageQuery({
        variables: { locale: locale },
    });

    const navigate = useNavigate();
    const { store } = useIndexeDb();
    const errorPage = data?.errorPage?.data?.attributes;
    const [dataToUse, setDataToUse] = useState<any>();
    const title = dataToUse?.title;
    const subtitle = dataToUse?.subtitle;
    const description = dataToUse?.description;
    const button = dataToUse?.button;

    let location = useLocation();
    const [showPage, setShowPage] = useState<boolean>(false);
    const locationPathName = location.pathname.replace("/", "");

    const createLocaleSlugs = (
        slugsPT: string[],
        slugsEN: string[],
        locale: string
    ) => {
        const object: { [key: string]: string } = {};
        if (locale === "pt") {
            slugsEN.forEach((slug: string, index: number) => {
                if (slugsPT[index]) {
                    object[slug] = slugsPT[index];
                } else {
                    object[`index-${index}`] = slugsPT[index];
                }
            });
        } else {
            slugsPT.forEach((slug: string, index: number) => {
                if (slugsEN[index]) {
                    object[slug] = slugsEN[index];
                } else {
                    object[`index-${index}`] = slugsPT[index];
                }
            });
        }
        return object;
    };

    useEffect(() => {
        updateSingleTypeData(data, errorPage, "errorPage", store, setDataToUse);
        // eslint-disable-next-line
    }, [data, store]);

    useEffect(() => {
        if (slugsPT?.length && slugsEN?.length) {
            const slugs = createLocaleSlugs(slugsPT, slugsEN, locale);

            if (slugs[locationPathName]) {
                navigate(
                    "/" +
                        createLocaleSlugs(slugsPT, slugsEN, locale)[
                            locationPathName
                        ]
                );
                setShowPage(false);
            }
            setShowPage(true);
        }
    }, [locale, locationPathName, navigate, slugsEN, slugsPT]);

    if (error) {
        console.log("Error: ", error);
    }

    return showPage ? (
        <>
            {!dataToUse && <Loading />}
            <section className="text-center lg:w-1/2 m-auto min-h-screen grid gap-6 content-start error-page">
                <h1 className="text-10xl font-bold">{title}</h1>{" "}
                <h2 className="text-7xl font-bold">{subtitle}</h2>{" "}
                <p className="text-6xl font-extralight">{description}</p>
                <Link
                    className="bg-mycarflix-blue hover:bg-mycarflix-section-bg duration-200 px-6 py-4 rounded-full text-white justify-self-center"
                    to={button?.url ?? "/"}
                >
                    {button?.label}
                </Link>
            </section>
        </>
    ) : (
        <section className="text-center lg:w-1/2 m-auto min-h-screen grid gap-6 content-start error-page"></section>
    );
}
