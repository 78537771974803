import DOMPurify from "dompurify";

export interface ImageProps {
    name: string;
    url: string;
}

interface StepProps {
    image: ImageProps | undefined;
    title: string | undefined;
    description: string | undefined;
}

export default function Step({ image, title, description }: StepProps) {
    const cleanedDescription = () => ({
        __html: DOMPurify.sanitize(description ?? ""),
    });

    return (
        <div className="grid gap-9 place-items-center rounded-4xl bg-mycarflix-section-bg text-white p-12 about-section-step sm:w-4/6 m-auto lg:w-auto h-full">
            <img className="" src={image?.url} alt={image?.name} />
            <h3 className="text-3xl font-bold">{title}</h3>
            <div
                className="self-start"
                dangerouslySetInnerHTML={cleanedDescription()}
            />
        </div>
    );
}
