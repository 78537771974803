import { useState, useEffect } from "react";
import debounce from "lodash/debounce";

interface UseWindowSizeProps {
    width: number | undefined;
    height: number | undefined;
}

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<UseWindowSizeProps>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        let handleResizeDebounced = debounce(() => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }, 250);

        window.addEventListener("resize", handleResizeDebounced);

        handleResizeDebounced();

        return () =>
            window.removeEventListener("resize", handleResizeDebounced);
    }, []);

    return windowSize;
};

export default useWindowSize;
