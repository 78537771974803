import {
    createContext,
    ReactNode,
    useContext,
    useState,
    useEffect,
} from "react";
import { useStaticPagesQuery } from "../generated";
// import { useGetLocalesQuery } from "../generated";

interface LocaleContextProps {
    locale: string;
    toggleLocale: any;
    slugsEN: any;
    slugsPT: any;
}

interface LocaleProviderProps {
    children: ReactNode;
}

const Locale = createContext<LocaleContextProps>({} as LocaleContextProps);

export const LocaleProvider = ({ children }: LocaleProviderProps) => {
    const storedLocale = localStorage.getItem("locale");
    const [locale, setLocale] = useState<string>(storedLocale ?? "pt");

    const { data: staticPagesEN } = useStaticPagesQuery({
        variables: { locale: "en" },
    });
    const { data: staticPagesPT } = useStaticPagesQuery({
        variables: { locale: "pt" },
    });

    const slugsEN = staticPagesEN?.staticPages?.data.map((staticPage: any) => {
        return staticPage?.attributes?.slug;
    });
    const slugsPT = staticPagesPT?.staticPages?.data.map((staticPage: any) => {
        return staticPage?.attributes?.slug;
    });

    //use this if we have more then "pt" and "en" locales
    // const { data, error, loading } = useGetLocalesQuery();

    // if (loading) {
    //   console.log("Loading...");
    // }

    // if (error) {
    //   console.log("Error: ", error);
    // }

    // const fullLocales = data?.i18NLocales?.data?.map(
    //   (locale: any) => locale?.attributes?.code
    // );

    useEffect(() => {
        if (storedLocale) {
            setLocale(storedLocale);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        localStorage.setItem("locale", locale);
    }, [locale]);

    const toggleLocale = () => {
        setLocale(locale === "en" ? "pt" : "en");
    };

    return (
        <Locale.Provider
            value={{
                locale,
                toggleLocale,
                slugsEN,
                slugsPT,
            }}
        >
            {children}
        </Locale.Provider>
    );
};

export function useLocale(): LocaleContextProps {
    const context = useContext(Locale);
    return context;
}
