import "./styles/index.css";
import Layout from "./components/Layout/Layout";
import Homepage from "./components/Homepage/Homepage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useLocale } from "./contexts/LocaleProvider";
import { useStaticPagesQuery } from "./generated";
import StaticPage from "./components/StaticPage/StaticPage";
import ErrorPage from "./components/Error/ErrorPage";
import { useEffect, useState } from "react";
import { useIndexeDb } from "./contexts/IndexedDdProvider";
import Loading from "./components/Loading/Loading";
import { updateColletionTypeData } from "./lib/indexed_db/indexed_db";
import CookiesPopUp from "./components/Cookies/CookiesPopUp";

export interface SeoProps {
    id: string;
    metaTitle: string;
    metaDescription: string;
    metaImage: any;
}

export interface StaticPageProps {
    slug: string;
    title: string;
    content: string;
}

export interface AttributesProps {
    attributes: StaticPageProps;
}

export default function App() {
    const { locale } = useLocale();
    const { data, error } = useStaticPagesQuery({
        variables: { locale: locale },
    });

    const { store } = useIndexeDb();
    const staticPages: AttributesProps[] | any = data?.staticPages?.data;
    const [dataToUse, setDataToUse] = useState<Array<{}>>();

    if (error) {
        console.log("error: ", error);
    }

    useEffect(() => {
        updateColletionTypeData(
            data,
            staticPages,
            "StaticPage",
            store,
            setDataToUse
        );
        // eslint-disable-next-line
    }, [data, store]);

    return (
        <>
            {!dataToUse && <Loading />}
            <div className="App">
                <BrowserRouter>
                    <Layout>
                        <Routes>
                            <Route path="/" element={<Homepage />} />
                            {dataToUse?.map(
                                (
                                    staticPage: AttributesProps | any,
                                    index: number
                                ) => {
                                    return (
                                        <Route
                                            key={
                                                staticPage.slug ??
                                                `hero-section-${index}`
                                            }
                                            path={`${staticPage.slug}`}
                                            element={
                                                <StaticPage
                                                    slug={staticPage.slug}
                                                    title={staticPage.title}
                                                    content={staticPage.content}
                                                />
                                            }
                                        />
                                    );
                                }
                            )}
                            <Route path="*" element={<ErrorPage />} />
                        </Routes>
                        <CookiesPopUp />
                    </Layout>
                </BrowserRouter>
                <div id="portal-root"></div>
            </div>
        </>
    );
}
