import { useLocale } from "../../../contexts/LocaleProvider";
import { useAboutQuery } from "../../../generated";
import Step from "./Step";
import { motion, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useIndexeDb } from "../../../contexts/IndexedDdProvider";
import Loading from "../../Loading/Loading";
import { updateSingleTypeData } from "../../../lib/indexed_db/indexed_db";

export default function About() {
    const stepsSection = useRef(null);
    const isInView = useInView(stepsSection, { once: true, amount: "some" });

    const { locale } = useLocale();
    const { data, error } = useAboutQuery({
        variables: { locale: locale },
    });

    const { store } = useIndexeDb();
    const about = data?.about?.data?.attributes;
    const [dataToUse, setDataToUse] = useState<any>();

    useEffect(() => {
        updateSingleTypeData(data, about, "aboutSection", store, setDataToUse);
        // eslint-disable-next-line
    }, [data, store]);

    const slug = dataToUse?.slug;
    const title = dataToUse?.title;
    const description = dataToUse?.description;
    const steps = dataToUse?.steps;

    if (error) {
        console.log("error: ", error);
    }

    return (
        <>
            {!dataToUse && <Loading />}
            <section id={slug ?? ""} className="text-center min-h-screen mt-28">
                <h2 className="text-white md:text-black text-4xl lg:text-6xl font-bold">
                    {title}
                </h2>
                <p className="text-white md:text-black lg:text-mycarflix-gray lg:text-xl mt-9 w-7/12 m-auto">
                    {description}
                </p>
                <div
                    ref={stepsSection}
                    className="grid lg:grid-flow-col gap-20 mt-20"
                >
                    {steps?.map((step: any, index: number) => {
                        return (
                            <motion.div
                                key={step?.id}
                                initial={{ y: 100, opacity: 0 }}
                                animate={isInView ? { y: 0, opacity: 1 } : {}}
                                transition={{ delay: index * 0.2 }}
                            >
                                <Step
                                    key={index}
                                    image={
                                        step?.image?.data?.attributes ??
                                        undefined
                                    }
                                    title={step?.title ?? ""}
                                    description={step?.description ?? ""}
                                />
                            </motion.div>
                        );
                    })}
                </div>
            </section>
        </>
    );
}
