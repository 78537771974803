import { motion } from "framer-motion";
import { MenuItem, MenuItemProps, menuItemVariants } from "./MenuItem";
import useWindowSize from "../../../hooks/useWindowSize";
import { isWindowDesktop } from "../../../lib/abstract/windowEvents";
import { useHeaderQuery } from "../../../generated";
import { LinkProps } from "../Header";
import { useLocale } from "../../../contexts/LocaleProvider";
import { useHeaderModal } from "../../../contexts/ModalProvider";
import { MenuItemLink } from "./MenuItemLink";
import Loading from "../../Loading/Loading";
import { useIndexeDb } from "../../../contexts/IndexedDdProvider";
import { useEffect, useState } from "react";
import { updateSingleTypeData } from "../../../lib/indexed_db/indexed_db";

interface NavigationProps {
    menu: any;
    account: any;
    language: any;
    className?: string;
}

const variants = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
};

export const Navigation = ({
    menu,
    account,
    language,
    className,
}: NavigationProps) => {
    const { locale, toggleLocale } = useLocale();
    const { isHeaderOpen } = useHeaderModal();

    const { data, error } = useHeaderQuery({
        variables: {
            locale: locale,
        },
    });
    const { store } = useIndexeDb();
    const header: any = data?.header?.data?.attributes;
    const [dataToUse, setDataToUse] = useState<any>();

    let { width } = useWindowSize();

    useEffect(() => {
        updateSingleTypeData(data, header, "header", store, setDataToUse);
        // eslint-disable-next-line
    }, [data, store]);

    if (error) {
        console.log("error: ", error);
    }

    return (
        <>
            {!dataToUse && <Loading />}
            {width && (
                <motion.ul
                    className={className}
                    variants={isWindowDesktop(width) ? undefined : variants}
                >
                    {menu?.map((menuItem: MenuItemProps, index: number) => {
                        return (
                            <MenuItem
                                className={"block"}
                                label={menuItem.label}
                                url={menuItem.url}
                                key={index}
                            />
                        );
                    })}

                    {!isWindowDesktop(width) && (
                        <motion.li
                            initial={{ width: isHeaderOpen ? "" : 0 }}
                            animate={{ width: isHeaderOpen ? "50%" : "" }}
                            transition={{ delay: isHeaderOpen ? 0.6 : 0 }}
                            variants={
                                isWindowDesktop(width)
                                    ? undefined
                                    : menuItemVariants
                            }
                            className="lg:hidden h-0.5 rounded bg-white my-4"
                        />
                    )}

                    {account?.map((menuItem: LinkProps, index: number) => {
                        return (
                            <MenuItemLink
                                key={index}
                                className={`text-center rounded-full font-bold tracking-widest ${
                                    index === 0
                                        ? "bg-white text-mycarflix-blue"
                                        : "border-white border-2"
                                }`}
                                url={menuItem.url}
                                label={menuItem.label}
                            />
                        );
                    })}

                    <motion.li
                        className="mt-16 lg:mt-0 text-center cursor-pointer"
                        variants={
                            isWindowDesktop(width)
                                ? undefined
                                : menuItemVariants
                        }
                        onClick={() => {
                            toggleLocale();
                            // setIsHeaderOpen(false);
                        }}
                    >
                        <button>{language}</button>
                    </motion.li>
                </motion.ul>
            )}
        </>
    );
};
