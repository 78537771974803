import { useEffect, useState } from "react";
import { useCookies } from "../../contexts/CookiesProvider";
import { useIndexeDb } from "../../contexts/IndexedDdProvider";
import { useLocale } from "../../contexts/LocaleProvider";
import { useMessageQuery } from "../../generated";
import { updateSingleTypeData } from "../../lib/indexed_db/indexed_db";
import CookiesModal from "./CookiesModal";

export default function CookiesPopUp() {
    const {
        showPopUp,
        setCookieObject,
        cookieObject,
        setIsCookiesModalOpen,
        isCookiesModalOpen,
    } = useCookies();

    const { store } = useIndexeDb();
    const { locale } = useLocale();

    const [dataToUse, setDataToUse] = useState<any>();

    const { data, error } = useMessageQuery({ variables: { locale: locale } });
    if (error) {
        console.log("error: ", error);
    }

    const cookiesPopUp =
        data?.message?.data?.attributes?.messages?.["cookies-popup"];

    useEffect(() => {
        updateSingleTypeData(
            data,
            cookiesPopUp,
            "cookiesPopUp",
            store,
            setDataToUse
        );
        // eslint-disable-next-line
    }, [data, store]);

    return (
        <>
            {showPopUp && (
                <article className="cookies-popup m-auto bg-transparent fixed bottom-0 grid w-full place-items-center z-10">
                    <div className="bg-white w-10/12 rounded-t-4xl grid lg:grid-flow-col p-7 gap-7 ">
                        <div className="grid gap-4">
                            <h2 className="text-mycarflix-blue text-xl font-bold">
                                {dataToUse?.title}
                            </h2>
                            <p className="text-sm">{dataToUse?.description}</p>
                        </div>
                        <div className="grid gap-4 items-center">
                            <button
                                className="duration-200 font-bold uppercase text-lg bg-mycarflix-blue hover:bg-mycarflix-section-bg text-white px-6 py-4 lg:text-base lg:px-12 rounded-full"
                                onClick={() => {
                                    setCookieObject({
                                        ...cookieObject,
                                        essentials: true,
                                        analytics: true,
                                    });
                                    // window.location.reload();
                                }}
                            >
                                {dataToUse?.acceptbutton}
                            </button>
                            <button
                                className="text-mycarflix-blue"
                                onClick={() =>
                                    setIsCookiesModalOpen(!isCookiesModalOpen)
                                }
                            >
                                {dataToUse?.modalbutton}
                            </button>
                        </div>
                    </div>
                </article>
            )}
            <CookiesModal />
        </>
    );
}
