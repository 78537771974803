import { useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useLocale } from "../../../contexts/LocaleProvider";
import { useAppQuery } from "../../../generated";
import { motion } from "framer-motion";
import {
    childVariants,
    containerVariants,
} from "../../../lib/animations/leftToRight";
import MobileAppLink from "./MobileAppLink";
import Loading from "../../Loading/Loading";
import { useIndexeDb } from "../../../contexts/IndexedDdProvider";
import { updateSingleTypeData } from "../../../lib/indexed_db/indexed_db";

export default function MobileApps() {
    const { locale } = useLocale();
    const { data, error } = useAppQuery({
        variables: {
            locale: locale,
        },
    });

    const { store } = useIndexeDb();
    const app = data?.app?.data?.attributes;
    const [dataToUse, setDataToUse] = useState<typeof app>();

    if (error) {
        console.log("error: ", error);
    }

    const title = dataToUse?.title;
    const description = dataToUse?.description;
    const image = dataToUse?.image?.data?.attributes;
    const button = dataToUse?.button;

    const appLinksTitle = dataToUse?.applinks?.title;
    const appLinksImages = dataToUse?.applinks?.imagelink;

    const container = useRef(null);
    const isInView = useInView(container, { once: true, amount: "some" });

    useEffect(() => {
        updateSingleTypeData(data, app, "appSection", store, setDataToUse);
        // eslint-disable-next-line
    }, [data, store]);

    return (
        <>
            {!dataToUse && <Loading />}
            <section className="grid lg:grid-cols-2 sm:w-2/3 lg:w-auto m-auto  min-h-screen mt-52 lg:m-0 lg:mt-52">
                <div>
                    <img src={image?.url} alt={image?.name} />
                </div>
                <motion.div
                    ref={container}
                    variants={containerVariants}
                    initial={"hidden"}
                    animate={isInView ? "visible" : ""}
                    className={"text-center lg:text-end"}
                >
                    <motion.h2
                        variants={childVariants}
                        className="text-4xl lg:text-6xl font-bold"
                    >
                        {title}
                    </motion.h2>
                    <motion.p
                        variants={childVariants}
                        className="text-mycarflix-gray lg:text-xl mt-9"
                    >
                        {description}
                    </motion.p>
                    <motion.a
                        href={button?.url ?? ""}
                        variants={childVariants}
                        className="button-animation bg-mycarflix-black-bg text-white hover:bg-black ease-in-out px-12 py-4 rounded-full inline-block mt-10 font-bold text-xs md:text-base lg:text-lg whitespace-nowrap"
                    >
                        {button?.label}
                    </motion.a>
                    <div className="mt-20 grid justify-center lg:justify-end">
                        <motion.h3
                            variants={childVariants}
                            className="font-bold lg:text-2xl mb-5 lg:justify-self-end"
                        >
                            {appLinksTitle}
                        </motion.h3>
                        <div className="flex gap-8 lg:gap-3 flex-col lg:flex-row">
                            <MobileAppLink appLinksImages={appLinksImages} />
                        </div>
                    </div>
                </motion.div>
            </section>
        </>
    );
}
