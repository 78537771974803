import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import {
    getCookie,
    createCookie,
    // deleteGaCookies,
} from "../lib/cookies/cookies";
import { firebaseConfig } from "../lib/firebase/firebase";
import { initializeApp } from "firebase/app";
import {
    ConsentStatusString,
    getAnalytics,
    setConsent,
} from "firebase/analytics";

interface CookieObjectProps {
    essentials: boolean;
    analytics: boolean;
}

interface CookiesContextProps {
    cookieObject: CookieObjectProps;
    setCookieObject: Dispatch<SetStateAction<any>>;
    showPopUp: boolean;
    setShowPopUp: Dispatch<SetStateAction<boolean>>;
    isCookiesModalOpen: boolean;
    setIsCookiesModalOpen: Dispatch<SetStateAction<boolean>>;
}

interface CookiesProviderProps {
    children: ReactNode;
}

const Cookies = createContext<CookiesContextProps>({} as CookiesContextProps);

export const CookiesProvider = ({ children }: CookiesProviderProps) => {
    const cookies = document.cookie.split(";");

    const isMycarflixCookiesCreated = cookies.some((cookie) =>
        cookie.trim().startsWith("mycarflix_cookies=")
    );

    const myCarflixCookie = isMycarflixCookiesCreated
        ? JSON.parse(getCookie("mycarflix_cookies"))
        : null;

    const [cookieObject, setCookieObject] = useState<CookieObjectProps>({
        essentials: myCarflixCookie?.essentials ?? false,
        analytics: myCarflixCookie?.analytics ?? false,
    });

    const [isCookiesModalOpen, setIsCookiesModalOpen] =
        useState<boolean>(false);

    const [showPopUp, setShowPopUp] = useState<boolean>(false);

    const app = initializeApp(firebaseConfig);
    getAnalytics(app);

    const DAYS_UNTIL_COOKIE_EXPIRES = 30;

    function getExpiredDate(days: number) {
        let date = new Date();
        return new Date(
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        );
    }

    useEffect(() => {
        if (!isMycarflixCookiesCreated) {
            createCookie(
                "mycarflix_cookies",
                '{"essentials":false, "analytics":false}',
                { expires: DAYS_UNTIL_COOKIE_EXPIRES, path: "/" }
            );
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (cookieObject?.analytics) {
            app.automaticDataCollectionEnabled = true;
            const ConsentStatusString: ConsentStatusString = "granted";
            setConsent({
                ad_storage: ConsentStatusString,
                analytics_storage: ConsentStatusString,
                functionality_storage: ConsentStatusString,
                personalization_storage: ConsentStatusString,
                security_storage: ConsentStatusString,
            });
        } else {
            app.automaticDataCollectionEnabled = false;
            const ConsentStatusString: ConsentStatusString = "denied";
            setConsent({
                ad_storage: ConsentStatusString,
                analytics_storage: ConsentStatusString,
                functionality_storage: ConsentStatusString,
                personalization_storage: ConsentStatusString,
                security_storage: ConsentStatusString,
            });
        }

        // if (!myCarflixCookie?.essential) {
        //   //   document.cookie = `_GRECAPTCHA=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        //   const getAllGaCookies = cookies.filter((cookie: any) =>
        //     cookie
        //   );
        //   console.log("getAllGaCookies", getAllGaCookies)
        //   const getGaCookieName = getAllGaCookies.map(
        //     (cookie: any) => cookie.split("=")[0]
        //   );

        //   getGaCookieName.forEach(
        //     (cookieName: any) =>
        //       (document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`)
        //   );
        // }

        if (cookieObject?.essentials) {
            document.cookie = `mycarflix_cookies=${JSON.stringify({
                ...cookieObject,
                essentials: true,
            })};expires=${getExpiredDate(DAYS_UNTIL_COOKIE_EXPIRES)};path=/;`;
            setShowPopUp(false);
        } else {
            document.cookie = `mycarflix_cookies=${JSON.stringify({
                ...cookieObject,
                essentials: false,
            })}; expires=${getExpiredDate(DAYS_UNTIL_COOKIE_EXPIRES)};path=/;`;
            setShowPopUp(true);
        }
        // eslint-disable-next-line
    }, [
        cookieObject,
        cookies,
        isMycarflixCookiesCreated,
        cookieObject?.essentials,
        cookieObject?.analytics,
    ]);

    return (
        <Cookies.Provider
            value={{
                showPopUp,
                setShowPopUp,
                isCookiesModalOpen,
                setIsCookiesModalOpen,
                cookieObject,
                setCookieObject,
            }}
        >
            {children}
        </Cookies.Provider>
    );
};

export function useCookies(): CookiesContextProps {
    const context = useContext(Cookies);
    return context;
}
