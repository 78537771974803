import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useIndexeDb } from "../../contexts/IndexedDdProvider";
import { useLocale } from "../../contexts/LocaleProvider";
import { useFooterQuery } from "../../generated";
import { updateSingleTypeData } from "../../lib/indexed_db/indexed_db";
import Loading from "../Loading/Loading";

export default function Footer() {
    const currentyear = new Date().getFullYear();
    const { locale } = useLocale();
    const { data, error } = useFooterQuery({
        variables: {
            locale: locale,
        },
    });

    if (error) {
        console.log("error: ", error);
    }

    const { store } = useIndexeDb();
    const footer = data?.footer?.data?.attributes;
    const [dataToUse, setDataToUse] = useState<any>();

    useEffect(() => {
        updateSingleTypeData(data, footer, "footer", store, setDataToUse);
        // eslint-disable-next-line
    }, [data, store]);

    const rights = dataToUse?.rights;
    const links = dataToUse?.link;

    return (
        <>
            {!dataToUse && <Loading />}
            <footer className="grid mt-20">
                <div className="w-1/12 h-0.5 bg-mycarflix-blue m-auto"></div>
                <nav className="flex flex-wrap justify-center w-full my-9">
                    {links?.map((link: any) => {
                        return (
                            <Link
                                key={link.url}
                                className="flex font-bold text-mycarflix-blue footer-link mr-7 items-center"
                                to={link.url}
                            >
                                {link.label}
                            </Link>
                        );
                    })}
                </nav>
                <div className="w-full text-white text-xs text-center bg-gradient-mycarflix-footer-bg p-7">
                    <span>© {`${currentyear} ${rights}`} </span>
                </div>
            </footer>
        </>
    );
}
