import { useEffect, useRef, useState } from "react";
import { useLocale } from "../../../contexts/LocaleProvider";
import { useHomepageQuery } from "../../../generated";
import DOMPurify from "dompurify";
import { AnimatePresence, motion, useInView } from "framer-motion";
import { useIndexeDb } from "../../../contexts/IndexedDdProvider";
import Loading from "../../Loading/Loading";
import { updateSingleTypeData } from "../../../lib/indexed_db/indexed_db";

export default function Hero() {
    const [isAnimated, setIsAnimated] = useState<boolean>(false);
    const { locale } = useLocale();
    const { data, error } = useHomepageQuery({
        variables: { locale: locale },
    });

    const { store } = useIndexeDb();
    const hero = data?.homepage?.data?.attributes;
    const [dataToUse, setDataToUse] = useState<any>();

    const container = useRef(null);
    const isInView = useInView(container, { once: true, amount: "some" });

    // const slug = dataToUse?.slug;
    const title = dataToUse?.title;
    const description = dataToUse?.description;
    const cleanedDescription = () => ({
        __html: DOMPurify.sanitize(description ?? ""),
    });
    const paragraphs = cleanedDescription()
        .__html.split("</p>")
        .filter((str) => str);
    const contact = dataToUse?.contact;
    const logo = dataToUse?.logo?.data?.attributes;

    if (error) {
        console.log("error: ", error);
    }

    useEffect(() => {
        updateSingleTypeData(data, hero, "heroSection", store, setDataToUse);
        // eslint-disable-next-line
    }, [data, store]);

    return (
        <>
            {!dataToUse && <Loading />}
            <section
                ref={container}
                className="lg:grid sm:w-2/3 lg:w-auto text-center lg:text-left m-auto lg:m-0 justify-self-center text-white min-h-half-screen hero-columns"
            >
                <div>
                    <AnimatePresence>
                        <motion.h1
                            key={"title"}
                            initial={{ opacity: 0, x: -75 }}
                            animate={isInView ? { opacity: 1, x: 0 } : {}}
                            className="text-4xl lg:text-6xl font-bold mt-9"
                        >
                            {title ?? ""}
                        </motion.h1>
                        {paragraphs?.map((paragraph, index) => {
                            return (
                                <motion.div
                                    key={`paragraph-${index}`}
                                    className={`ease-in-out mt-8 lg:text-xl ${index}`}
                                    ref={container}
                                    initial={{ opacity: 0, x: -75 }}
                                    animate={
                                        isInView ? { opacity: 1, x: 0 } : {}
                                    }
                                    transition={{
                                        duration: 0.3,
                                        delay: index * 0.3,
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: paragraph,
                                    }}
                                    onAnimationStart={() => {
                                        setIsAnimated(false);
                                    }}
                                    onAnimationComplete={() => {
                                        if (index === paragraphs.length - 1) {
                                            setIsAnimated(true);
                                        }
                                    }}
                                />
                            );
                        })}
                        <motion.a
                            key={"button"}
                            initial={{ opacity: 0, x: -75 }}
                            animate={
                                isInView && isAnimated
                                    ? { opacity: 1, x: 0 }
                                    : {}
                            }
                            className={`button-animation bg-mycarflix-black-bg hover:bg-black ease-in-out px-12 py-4 rounded-full inline-block mt-10 font-bold text-base lg:text-lg `}
                            href={contact?.url ?? ""}
                        >
                            {contact?.label}
                        </motion.a>
                        <motion.img
                            key={"logo"}
                            initial={{ opacity: 0, x: -75 }}
                            animate={
                                isInView && isAnimated
                                    ? {
                                        opacity: 1,
                                        x: 0,
                                        transition: { delay: 0.2 },
                                    }
                                    : {}
                            }
                            className="mt-52 h-5"
                            src={logo?.url}
                            alt={logo?.name}
                        />
                    </AnimatePresence>
                </div>
            </section>
        </>
    );
}
