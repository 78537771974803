import { useEffect, useState } from "react";
import { useHeaderModal } from "../../contexts/ModalProvider";
import { useLocale } from "../../contexts/LocaleProvider";
import { useHeaderQuery } from "../../generated";
import useWindowSize from "../../hooks/useWindowSize";
import { motion } from "framer-motion";
import { Navigation } from "./Navigation/Navigation";
import { MenuToggle } from "./Navigation/MenuToggle";
import { isWindowDesktop } from "../../lib/abstract/windowEvents";
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading";
import { useIndexeDb } from "../../contexts/IndexedDdProvider";
import { updateSingleTypeData } from "../../lib/indexed_db/indexed_db";

export interface LinkProps {
    __typename?: string;
    id: string;
    label: string;
    url: string;
}

export default function Header() {
    const { locale } = useLocale();
    const { data, error } = useHeaderQuery({
        variables: {
            locale: locale,
        },
    });

    const { store } = useIndexeDb();
    const header: any = data?.header?.data?.attributes;
    const [dataToUse, setDataToUse] = useState<any>();
    const { isHeaderOpen, setIsHeaderOpen, toggleMenu } = useHeaderModal();
    const { width } = useWindowSize();

    const menu: Array<LinkProps> = dataToUse?.menu;
    const account: Array<LinkProps> = dataToUse?.account;
    const language: string = dataToUse?.language;
    const logo: any = dataToUse?.logo?.data?.attributes;

    useEffect(() => {
        if (width && width > 1024) {
            setIsHeaderOpen(true);
        }
    }, [width, setIsHeaderOpen]);

    useEffect(() => {
        updateSingleTypeData(data, header, "header", store, setDataToUse);
        // eslint-disable-next-line
    }, [data, store]);

    if (error) {
        console.log("error: ", error);
    }
    return (
        <>
            {!dataToUse && <Loading />}
            <motion.header
                id="header"
                animate={isHeaderOpen ? "open" : "closed"}
                className={`z-50 bg-mycarflix-blue flex justify-center sticky top-0 ${
                    isHeaderOpen && !isWindowDesktop(width)
                        ? "header-active"
                        : ""
                }`}
            >
                <div className="flex justify-between items-center p-6 px-8 sticky top-0 w-full gap-x-4 max-w-7xl mt-4">
                    <div
                        className={
                            "flex-grow flex justify-center lg:justify-start"
                        }
                    >
                        <Link className="flex" to={"/#"}>
                            <img
                                src={logo?.url}
                                className={`h-full`}
                                alt="logo"
                            />
                        </Link>
                    </div>
                    <Navigation
                        menu={menu}
                        account={account}
                        language={language}
                        className={`hidden lg:flex gap-10 z-50 items-center text-white`}
                    />
                    <MenuToggle
                        className="text-white lg:hidden"
                        toggle={() => toggleMenu()}
                    />
                </div>
            </motion.header>
        </>
    );
}
