import { motion } from "framer-motion";
import useWindowSize from "../../../hooks/useWindowSize";

const Path = (props: any) => (
    <motion.path
        fill="transparent"
        strokeWidth="1.5"
        stroke="currentColor"
        strokeLinecap="square"
        {...props}
    />
);

export const MenuToggle = ({ toggle, className }: any) => {
    const { width } = useWindowSize();
    return (
        <>
            {width && (
                <button className={className} onClick={toggle}>
                    <svg width="28" height="28" viewBox="0 0 23 23">
                        <Path
                            variants={{
                                closed: { d: "M 2 2.5 L 20 2.5" },
                                open: { d: "M 3 16.5 L 17 2.5" },
                            }}
                        />
                        <Path
                            d="M 2 9.423 L 20 9.423"
                            variants={{
                                closed: { opacity: 1 },
                                open: { opacity: 0 },
                            }}
                            transition={{ duration: 0.1 }}
                        />
                        <Path
                            variants={{
                                closed: { d: "M 2 16.346 L 20 16.346" },
                                open: { d: "M 3 2.5 L 17 16.346" },
                            }}
                        />
                    </svg>
                </button>
            )}
        </>
    );
};
