import { Dispatch, SetStateAction } from "react";

export function acordionFaq() {
    const buttons = document.querySelectorAll(
        ".tab-control"
    ) as NodeListOf<HTMLElement>;
    const sections = document.querySelectorAll(
        ".tab-panel"
    ) as NodeListOf<HTMLElement>;

    buttons.forEach((button) => {
        button.addEventListener("click", () => {
            buttons.forEach((b) => b.classList.remove("tab-active"));
            button.classList.add("tab-active");

            sections.forEach((section) => {
                section.style.display = "none";
            });
            let tabValue = button.getAttribute("data-tab");
            const targetSection = document.querySelector(
                `#${tabValue}`
            ) as HTMLElement;
            targetSection.style.display = "block";
        });
    });

    buttons.forEach((button) => {
        let tabValue = button.getAttribute("data-tab");
        const targetSection = document.querySelector(
            `#${tabValue}`
        ) as HTMLElement;
        if (button.classList.contains("tab-active")) {
            targetSection.style.display = "block";
        } else {
            targetSection.style.display = "none";
        }
    });
}

export function toggleCookiesModel(setter: Dispatch<SetStateAction<boolean>>) {
    const cookiesButton = document.querySelector(
        "#open-cookies-modal-button"
    ) as HTMLElement;

    if (cookiesButton) {
        cookiesButton.addEventListener("click", () => {
            setter(true);
        });
    }
}
