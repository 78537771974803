interface ToggleProps {
    id: string;
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    className?: string;
}

export default function Toggle({
    id,
    label,
    checked,
    onChange,
    className,
}: ToggleProps) {

    const handleToggleChange = () => {
        onChange(!checked);
    };

    return (
        <div className={`toggle ${className}`}>
            <label htmlFor={id}>{label}</label>
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={handleToggleChange}
            />
        </div>
    );
}
