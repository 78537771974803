import UAParser from "ua-parser-js";

export const getMobileOS = (): string => {
    let parser = new UAParser();

    if (parser.getOS().name?.includes("Android")) {
        return "android";
    } else if (parser.getOS()?.name === "iOS") {
        return "ios";
    } else {
        return "other";
    }
};
