import { useEffect, useRef } from "react";

/**
 *
 * @param ref reference to the element you want to get with out height
 * @returns returns the dimensions of the element by ref
 */
export const useDimensions = (ref: any) => {
    const dimensions = useRef({ width: 0, height: 0 });

    useEffect(() => {
        dimensions.current.width = ref.current.offsetWidth;
        dimensions.current.height = ref.current.offsetHeight;
    }, [ref]);

    return dimensions.current;
};
