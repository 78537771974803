interface cookieOptions {
    expires?: number;
    path?: string;
    domain?: string;
    secure?: boolean;
    sameSite?: string;
}

// interface createCookieProps {
//   name: string;
//   value: string;
//   options?: cookieOptions;
// }

export function createCookie(
    name: string,
    value: string,
    options?: cookieOptions
): void {
    let cookieString = `${name}=${value}`;
    if (options) {
        if (options.expires) {
            let date = new Date();
            date.setTime(
                date.getTime() + options.expires * 24 * 60 * 60 * 1000
            );
            cookieString += `;expires=${date.toUTCString()}`;
        }
        if (options.path) {
            cookieString += `;path=${options.path}`;
        }
        if (options.domain) {
            cookieString += `;domain=${options.domain}`;
        }
        if (options.secure) {
            cookieString += `;secure`;
        }
        if (options.sameSite) {
            cookieString += `;samesite=${options.sameSite}`;
        }
    }
    document.cookie = `${cookieString}`;
}

export function getCookie(name: string) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) === " ") {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length + 1, cookie.length);
        }
    }
    return "";
}

export const deleteGaCookies = (cookies: any) => {
    const getAllGaCookies = cookies.filter((cookie: any) =>
        cookie.includes("_ga")
    );
    const getGaCookieName = getAllGaCookies.map(
        (cookie: any) => cookie.split("=")[0]
    );

    getGaCookieName.forEach(
        (cookieName: any) =>
            (document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`)
    );
};
