import Dexie from "dexie";
import { isEqual } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { AttributesProps } from "../../App";

//Collection type
const retrieveCollectionTypeData = async (
    tableName: string,
    store: Dexie,
    setDataToUse: Dispatch<SetStateAction<any>>
) => {
    try {
        const response = await store.table(tableName).toArray();
        setDataToUse(response);
        return response;
    } catch (error) {
        console.log("Error: ", error);
    }
};

const saveCollectionTypeToIndexedDb = async (
    data: AttributesProps[],
    tableName: string,
    store: Dexie
) => {
    data?.map(async (staticPage: AttributesProps) => {
        try {
            await store.table(tableName).put(staticPage);
        } catch (error) {
            console.log("Error: ", error);
        }
    });
};

export const updateColletionTypeData = async (
    dataFromQuery: any,
    specificData: any,
    tableName: string,
    store: Dexie,
    setDataToUse: Dispatch<SetStateAction<any>>
) => {
    retrieveCollectionTypeData(tableName, store, setDataToUse)
        .then((result: any) => {
            let dataWithoutAttibuteField = specificData?.map(
                (data: any) => data.attributes
            );
            let resultWithoutAttributeField = result?.map(
                (data: any) => data.attributes
            );

            if (result?.length) {
                setDataToUse(resultWithoutAttributeField);
            } else if (dataFromQuery) {
                saveCollectionTypeToIndexedDb(specificData, tableName, store);
                setDataToUse(dataWithoutAttibuteField);
            }

            if (
                dataFromQuery &&
                result?.length &&
                !isEqual(dataWithoutAttibuteField, resultWithoutAttributeField)
            ) {
                saveCollectionTypeToIndexedDb(specificData, tableName, store);
                setDataToUse(dataWithoutAttibuteField);
            }
        })
        .catch((error) => {
            console.error("Error retrieving data", error);
        });
};

//Single Type
const saveSingleTypeToIndexedDb = async (
    data: any,
    tableName: string,
    store: Dexie
) => {
    try {
        await store.table(tableName).put({ ...data });
    } catch (error) {
        console.log("Error: ", error);
    }
};

const retrieveSingleTypeData = async (
    tableName: string,
    store: Dexie,
    setDataToUse: Dispatch<SetStateAction<any>>
) => {
    try {
        const response = await store.table(tableName).toArray();
        setDataToUse(response[0]);
        return response;
    } catch (error) {
        console.log("Error: ", error);
    }
};

export const updateSingleTypeData = async (
    dataFromQuery: any,
    specificData: any,
    tableName: string,
    store: Dexie,
    setDataToUse: Dispatch<SetStateAction<any>>
) => {
    retrieveSingleTypeData(tableName, store, setDataToUse)
        .then((result: any) => {
            if (result?.length) {
                setDataToUse(result[0]);
            } else if (dataFromQuery) {
                saveSingleTypeToIndexedDb(specificData, tableName, store);
                setDataToUse(specificData);
            }

            if (
                dataFromQuery &&
                result?.length &&
                !isEqual(result[0], specificData)
            ) {
                saveSingleTypeToIndexedDb(specificData, tableName, store);
                setDataToUse(specificData);
            }
        })
        .catch((error) => {
            console.error("Error retrieving data", error);
        });
};
