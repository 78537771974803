/**
 * @param {number | undefined} width width of window
 * @returns if window is greater or equal to 1024
 */
export function isWindowDesktop(width: number | undefined) {
    if (width !== undefined && width >= 1024) {
        return true;
    }
    return false;
}
