import { useEffect, useState } from "react";

import { useImageQuery } from "../../../generated";

import { useLocale } from "../../../contexts/LocaleProvider";
import { updateSingleTypeData } from "../../../lib/indexed_db/indexed_db";
import { useIndexeDb } from "../../../contexts/IndexedDdProvider";
import Loading from "../../Loading/Loading";

export default function ImageSection() {
    const { locale } = useLocale();
    const { data, error } = useImageQuery({
        variables: {
            locale: locale,
        },
    });
    const { store } = useIndexeDb();

    const images = data?.image?.data?.attributes;

    const [dataToUse, setDataToUse] = useState<typeof images>();

    if (error) {
        console.log("error on image section: ", error);
    }

    useEffect(() => {
        updateSingleTypeData(data, images, "imageSection", store, setDataToUse);
        // eslint-disable-next-line
    }, [data, store]);

    return (
        <>
            {!dataToUse && <Loading />}
            <section className="grid md:grid-cols-2 lg:grid-cols-3 justify-center">
                {dataToUse?.images?.data.map((image) => (
                    <img
                        src={image.attributes?.url ?? ""}
                        alt={image.attributes?.name ?? ""}
                    />
                ))}
            </section>
        </>
    );
}
