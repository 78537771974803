import DOMPurify from "dompurify";
import { useEffect, useLayoutEffect } from "react";
import { StaticPageProps } from "../../App";
import { useCookies } from "../../contexts/CookiesProvider";
import {
    acordionFaq,
    toggleCookiesModel,
} from "../../lib/abstract/staticPages";

export default function StaticPage({ slug, title, content }: StaticPageProps) {
    const { setIsCookiesModalOpen } = useCookies();
    const cleanedContent = () => ({
        __html: DOMPurify.sanitize(content ?? ""),
    });

    useEffect(() => {
        acordionFaq();
        toggleCookiesModel(setIsCookiesModalOpen);
    }, [content, setIsCookiesModalOpen]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [content]);

    return (
        <>
            <section
                id={slug}
                className="grid lg:w-1/2 m-auto min-h-screen content-start static-page"
            >
                <h1 className="text-4xl lg:text-5xl font-bold px-5 my-6 text-mycarflix-blue mt-8">
                    {title}
                </h1>
                <article
                    className="grid px-5 gap-4"
                    dangerouslySetInnerHTML={cleanedContent()}
                />
            </section>
        </>
    );
}
