import { Dispatch, ReactNode, SetStateAction } from "react";
import Close from "../../icons/Close";
import Portal from "../Portal/Portal";

interface ModalProps {
    isOpen: boolean;
    onClose: Dispatch<SetStateAction<boolean>>;
    children: ReactNode;
}

export default function Modal({ isOpen, onClose, children }: ModalProps) {
    const handleCloseOverlay = () => {
        onClose(false);
    };

    const handleClose = (event: any) => {
        event.stopPropagation();
        onClose(false);
    };
    return isOpen ? (
        <Portal>
            <div
                className="modal-overlay "
                onClick={() => handleCloseOverlay()}
            >
                <div className="modal bg-white rounded-4xl m-6">
                    <button
                        className="modal-close"
                        onClick={(event) => handleClose(event)}
                    >
                        <Close />
                    </button>
                    {children}
                </div>
            </div>
        </Portal>
    ) : null;
}
