import useWindowSize from "../hooks/useWindowSize";
import { isWindowDesktop } from "../lib/abstract/windowEvents";
import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useState,
} from "react";

interface ModalContextProps {
    isHeaderOpen: boolean;
    setIsHeaderOpen: Dispatch<SetStateAction<boolean>>;
    toggleMenu(): void;
    closeHeader(): void;
}

interface ModalProviderProps {
    children: ReactNode;
}

const Header = createContext<ModalContextProps>({} as ModalContextProps);

export const ModalProvider = ({ children }: ModalProviderProps) => {
    const [isHeaderOpen, setIsHeaderOpen] = useState<boolean>(false);
    const { width } = useWindowSize();

    const toggleMenu = () => {
        setIsHeaderOpen(!isHeaderOpen);
    };

    const closeHeader = () => {
        if (!isWindowDesktop(width)) {
            setIsHeaderOpen(false);
        }
    };

    return (
        <Header.Provider
            value={{
                isHeaderOpen,
                setIsHeaderOpen,
                toggleMenu,
                closeHeader,
            }}
        >
            {children}
        </Header.Provider>
    );
};

export function useHeaderModal(): ModalContextProps {
    const context = useContext(Header);
    return context;
}
