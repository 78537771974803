import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    I18NLocaleCode: any;
    JSON: any;
    Upload: any;
};

export type About = {
    __typename?: "About";
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<AboutRelationResponseCollection>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    slug?: Maybe<Scalars["String"]>;
    steps?: Maybe<Array<Maybe<ComponentLayoutSteps>>>;
    title?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AboutLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>;
};

export type AboutStepsArgs = {
    filters?: InputMaybe<ComponentLayoutStepsFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type AboutEntity = {
    __typename?: "AboutEntity";
    attributes?: Maybe<About>;
    id?: Maybe<Scalars["ID"]>;
};

export type AboutEntityResponse = {
    __typename?: "AboutEntityResponse";
    data?: Maybe<AboutEntity>;
};

export type AboutInput = {
    description?: InputMaybe<Scalars["String"]>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
    slug?: InputMaybe<Scalars["String"]>;
    steps?: InputMaybe<Array<InputMaybe<ComponentLayoutStepsInput>>>;
    title?: InputMaybe<Scalars["String"]>;
};

export type AboutRelationResponseCollection = {
    __typename?: "AboutRelationResponseCollection";
    data: Array<AboutEntity>;
};

export type App = {
    __typename?: "App";
    applinks?: Maybe<ComponentLayoutApp>;
    button?: Maybe<ComponentLayoutLink>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    image?: Maybe<UploadFileEntityResponse>;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<AppRelationResponseCollection>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    slug?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AppLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>;
};

export type AppEntity = {
    __typename?: "AppEntity";
    attributes?: Maybe<App>;
    id?: Maybe<Scalars["ID"]>;
};

export type AppEntityResponse = {
    __typename?: "AppEntityResponse";
    data?: Maybe<AppEntity>;
};

export type AppInput = {
    applinks?: InputMaybe<ComponentLayoutAppInput>;
    button?: InputMaybe<ComponentLayoutLinkInput>;
    description?: InputMaybe<Scalars["String"]>;
    image?: InputMaybe<Scalars["ID"]>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
    slug?: InputMaybe<Scalars["String"]>;
    title?: InputMaybe<Scalars["String"]>;
};

export type AppRelationResponseCollection = {
    __typename?: "AppRelationResponseCollection";
    data: Array<AppEntity>;
};

export type BooleanFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]>>>;
    between?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]>>>;
    contains?: InputMaybe<Scalars["Boolean"]>;
    containsi?: InputMaybe<Scalars["Boolean"]>;
    endsWith?: InputMaybe<Scalars["Boolean"]>;
    eq?: InputMaybe<Scalars["Boolean"]>;
    eqi?: InputMaybe<Scalars["Boolean"]>;
    gt?: InputMaybe<Scalars["Boolean"]>;
    gte?: InputMaybe<Scalars["Boolean"]>;
    in?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]>>>;
    lt?: InputMaybe<Scalars["Boolean"]>;
    lte?: InputMaybe<Scalars["Boolean"]>;
    ne?: InputMaybe<Scalars["Boolean"]>;
    not?: InputMaybe<BooleanFilterInput>;
    notContains?: InputMaybe<Scalars["Boolean"]>;
    notContainsi?: InputMaybe<Scalars["Boolean"]>;
    notIn?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]>>>;
    notNull?: InputMaybe<Scalars["Boolean"]>;
    null?: InputMaybe<Scalars["Boolean"]>;
    or?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]>>>;
    startsWith?: InputMaybe<Scalars["Boolean"]>;
};

export type ComponentLayoutApp = {
    __typename?: "ComponentLayoutApp";
    id: Scalars["ID"];
    imagelink?: Maybe<Array<Maybe<ComponentLayoutImageLink>>>;
    title?: Maybe<Scalars["String"]>;
};

export type ComponentLayoutAppImagelinkArgs = {
    filters?: InputMaybe<ComponentLayoutImageLinkFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ComponentLayoutAppInput = {
    id?: InputMaybe<Scalars["ID"]>;
    imagelink?: InputMaybe<Array<InputMaybe<ComponentLayoutImageLinkInput>>>;
    title?: InputMaybe<Scalars["String"]>;
};

export type ComponentLayoutImageLink = {
    __typename?: "ComponentLayoutImageLink";
    id: Scalars["ID"];
    image?: Maybe<UploadFileEntityResponse>;
    name?: Maybe<Scalars["String"]>;
    url?: Maybe<Scalars["String"]>;
};

export type ComponentLayoutImageLinkFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<ComponentLayoutImageLinkFiltersInput>>>;
    name?: InputMaybe<StringFilterInput>;
    not?: InputMaybe<ComponentLayoutImageLinkFiltersInput>;
    or?: InputMaybe<Array<InputMaybe<ComponentLayoutImageLinkFiltersInput>>>;
    url?: InputMaybe<StringFilterInput>;
};

export type ComponentLayoutImageLinkInput = {
    id?: InputMaybe<Scalars["ID"]>;
    image?: InputMaybe<Scalars["ID"]>;
    name?: InputMaybe<Scalars["String"]>;
    url?: InputMaybe<Scalars["String"]>;
};

export type ComponentLayoutInnputNumber = {
    __typename?: "ComponentLayoutInnputNumber";
    id: Scalars["ID"];
    label?: Maybe<Scalars["String"]>;
};

export type ComponentLayoutInnputNumberInput = {
    id?: InputMaybe<Scalars["ID"]>;
    label?: InputMaybe<Scalars["String"]>;
};

export type ComponentLayoutInputEmail = {
    __typename?: "ComponentLayoutInputEmail";
    id: Scalars["ID"];
    label?: Maybe<Scalars["String"]>;
};

export type ComponentLayoutInputEmailInput = {
    id?: InputMaybe<Scalars["ID"]>;
    label?: InputMaybe<Scalars["String"]>;
};

export type ComponentLayoutInputMessage = {
    __typename?: "ComponentLayoutInputMessage";
    id: Scalars["ID"];
    label?: Maybe<Scalars["String"]>;
};

export type ComponentLayoutInputMessageInput = {
    id?: InputMaybe<Scalars["ID"]>;
    label?: InputMaybe<Scalars["String"]>;
};

export type ComponentLayoutInputText = {
    __typename?: "ComponentLayoutInputText";
    id: Scalars["ID"];
    label?: Maybe<Scalars["String"]>;
};

export type ComponentLayoutInputTextInput = {
    id?: InputMaybe<Scalars["ID"]>;
    label?: InputMaybe<Scalars["String"]>;
};

export type ComponentLayoutLink = {
    __typename?: "ComponentLayoutLink";
    id: Scalars["ID"];
    label: Scalars["String"];
    url?: Maybe<Scalars["String"]>;
};

export type ComponentLayoutLinkFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<ComponentLayoutLinkFiltersInput>>>;
    label?: InputMaybe<StringFilterInput>;
    not?: InputMaybe<ComponentLayoutLinkFiltersInput>;
    or?: InputMaybe<Array<InputMaybe<ComponentLayoutLinkFiltersInput>>>;
    url?: InputMaybe<StringFilterInput>;
};

export type ComponentLayoutLinkInput = {
    id?: InputMaybe<Scalars["ID"]>;
    label?: InputMaybe<Scalars["String"]>;
    url?: InputMaybe<Scalars["String"]>;
};

export type ComponentLayoutSteps = {
    __typename?: "ComponentLayoutSteps";
    description?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    image?: Maybe<UploadFileEntityResponse>;
    title?: Maybe<Scalars["String"]>;
};

export type ComponentLayoutStepsFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<ComponentLayoutStepsFiltersInput>>>;
    description?: InputMaybe<StringFilterInput>;
    not?: InputMaybe<ComponentLayoutStepsFiltersInput>;
    or?: InputMaybe<Array<InputMaybe<ComponentLayoutStepsFiltersInput>>>;
    title?: InputMaybe<StringFilterInput>;
};

export type ComponentLayoutStepsInput = {
    description?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["ID"]>;
    image?: InputMaybe<Scalars["ID"]>;
    title?: InputMaybe<Scalars["String"]>;
};

export type ComponentSharedMetaSocial = {
    __typename?: "ComponentSharedMetaSocial";
    description: Scalars["String"];
    id: Scalars["ID"];
    image?: Maybe<UploadFileEntityResponse>;
    socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
    title: Scalars["String"];
};

export type ComponentSharedMetaSocialFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
    description?: InputMaybe<StringFilterInput>;
    not?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
    or?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
    socialNetwork?: InputMaybe<StringFilterInput>;
    title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedMetaSocialInput = {
    description?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["ID"]>;
    image?: InputMaybe<Scalars["ID"]>;
    socialNetwork?: InputMaybe<Enum_Componentsharedmetasocial_Socialnetwork>;
    title?: InputMaybe<Scalars["String"]>;
};

export type ComponentSharedSeo = {
    __typename?: "ComponentSharedSeo";
    id: Scalars["ID"];
    keyWord?: Maybe<Scalars["String"]>;
    metaDescription: Scalars["String"];
    metaImage: UploadFileEntityResponse;
    metaSocial?: Maybe<Array<Maybe<ComponentSharedMetaSocial>>>;
    metaTitle: Scalars["String"];
};

export type ComponentSharedSeoMetaSocialArgs = {
    filters?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ComponentSharedSeoInput = {
    id?: InputMaybe<Scalars["ID"]>;
    keyWord?: InputMaybe<Scalars["String"]>;
    metaDescription?: InputMaybe<Scalars["String"]>;
    metaImage?: InputMaybe<Scalars["ID"]>;
    metaSocial?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialInput>>>;
    metaTitle?: InputMaybe<Scalars["String"]>;
};

export type ContactForm = {
    __typename?: "ContactForm";
    button?: Maybe<ComponentLayoutLink>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    email?: Maybe<ComponentLayoutInputEmail>;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<ContactFormRelationResponseCollection>;
    message?: Maybe<ComponentLayoutInputMessage>;
    name?: Maybe<ComponentLayoutInputText>;
    phone?: Maybe<ComponentLayoutInnputNumber>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    slug?: Maybe<Scalars["String"]>;
    terms?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContactFormLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>;
};

export type ContactFormEntity = {
    __typename?: "ContactFormEntity";
    attributes?: Maybe<ContactForm>;
    id?: Maybe<Scalars["ID"]>;
};

export type ContactFormEntityResponse = {
    __typename?: "ContactFormEntityResponse";
    data?: Maybe<ContactFormEntity>;
};

export type ContactFormInput = {
    button?: InputMaybe<ComponentLayoutLinkInput>;
    email?: InputMaybe<ComponentLayoutInputEmailInput>;
    message?: InputMaybe<ComponentLayoutInputMessageInput>;
    name?: InputMaybe<ComponentLayoutInputTextInput>;
    phone?: InputMaybe<ComponentLayoutInnputNumberInput>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
    slug?: InputMaybe<Scalars["String"]>;
    terms?: InputMaybe<Scalars["String"]>;
    title?: InputMaybe<Scalars["String"]>;
};

export type ContactFormRelationResponseCollection = {
    __typename?: "ContactFormRelationResponseCollection";
    data: Array<ContactFormEntity>;
};

export type ContactMessage = {
    __typename?: "ContactMessage";
    createdAt?: Maybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<ContactMessageRelationResponseCollection>;
    message: Scalars["String"];
    name: Scalars["String"];
    phone?: Maybe<Scalars["String"]>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    recaptchaResponse: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ContactMessageLocalizationsArgs = {
    filters?: InputMaybe<ContactMessageFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    publicationState?: InputMaybe<PublicationState>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContactMessageEntity = {
    __typename?: "ContactMessageEntity";
    attributes?: Maybe<ContactMessage>;
    id?: Maybe<Scalars["ID"]>;
};

export type ContactMessageEntityResponse = {
    __typename?: "ContactMessageEntityResponse";
    data?: Maybe<ContactMessageEntity>;
};

export type ContactMessageEntityResponseCollection = {
    __typename?: "ContactMessageEntityResponseCollection";
    data: Array<ContactMessageEntity>;
    meta: ResponseCollectionMeta;
};

export type ContactMessageFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<ContactMessageFiltersInput>>>;
    createdAt?: InputMaybe<DateTimeFilterInput>;
    email?: InputMaybe<StringFilterInput>;
    id?: InputMaybe<IdFilterInput>;
    locale?: InputMaybe<StringFilterInput>;
    localizations?: InputMaybe<ContactMessageFiltersInput>;
    message?: InputMaybe<StringFilterInput>;
    name?: InputMaybe<StringFilterInput>;
    not?: InputMaybe<ContactMessageFiltersInput>;
    or?: InputMaybe<Array<InputMaybe<ContactMessageFiltersInput>>>;
    phone?: InputMaybe<StringFilterInput>;
    publishedAt?: InputMaybe<DateTimeFilterInput>;
    recaptchaResponse?: InputMaybe<StringFilterInput>;
    updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ContactMessageInput = {
    email?: InputMaybe<Scalars["String"]>;
    message?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    phone?: InputMaybe<Scalars["String"]>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
    recaptchaResponse?: InputMaybe<Scalars["String"]>;
};

export type ContactMessageRelationResponseCollection = {
    __typename?: "ContactMessageRelationResponseCollection";
    data: Array<ContactMessageEntity>;
};

export type DateTimeFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
    between?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
    contains?: InputMaybe<Scalars["DateTime"]>;
    containsi?: InputMaybe<Scalars["DateTime"]>;
    endsWith?: InputMaybe<Scalars["DateTime"]>;
    eq?: InputMaybe<Scalars["DateTime"]>;
    eqi?: InputMaybe<Scalars["DateTime"]>;
    gt?: InputMaybe<Scalars["DateTime"]>;
    gte?: InputMaybe<Scalars["DateTime"]>;
    in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
    lt?: InputMaybe<Scalars["DateTime"]>;
    lte?: InputMaybe<Scalars["DateTime"]>;
    ne?: InputMaybe<Scalars["DateTime"]>;
    not?: InputMaybe<DateTimeFilterInput>;
    notContains?: InputMaybe<Scalars["DateTime"]>;
    notContainsi?: InputMaybe<Scalars["DateTime"]>;
    notIn?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
    notNull?: InputMaybe<Scalars["Boolean"]>;
    null?: InputMaybe<Scalars["Boolean"]>;
    or?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
    startsWith?: InputMaybe<Scalars["DateTime"]>;
};

export enum Enum_Componentsharedmetasocial_Socialnetwork {
    Facebook = "Facebook",
    Twitter = "Twitter",
}

export type ErrorPage = {
    __typename?: "ErrorPage";
    button?: Maybe<ComponentLayoutLink>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<ErrorPageRelationResponseCollection>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    slug?: Maybe<Scalars["String"]>;
    subtitle?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ErrorPageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>;
};

export type ErrorPageEntity = {
    __typename?: "ErrorPageEntity";
    attributes?: Maybe<ErrorPage>;
    id?: Maybe<Scalars["ID"]>;
};

export type ErrorPageEntityResponse = {
    __typename?: "ErrorPageEntityResponse";
    data?: Maybe<ErrorPageEntity>;
};

export type ErrorPageInput = {
    button?: InputMaybe<ComponentLayoutLinkInput>;
    description?: InputMaybe<Scalars["String"]>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
    slug?: InputMaybe<Scalars["String"]>;
    subtitle?: InputMaybe<Scalars["String"]>;
    title?: InputMaybe<Scalars["String"]>;
};

export type ErrorPageRelationResponseCollection = {
    __typename?: "ErrorPageRelationResponseCollection";
    data: Array<ErrorPageEntity>;
};

export type FileInfoInput = {
    alternativeText?: InputMaybe<Scalars["String"]>;
    caption?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
};

export type FloatFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
    between?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
    contains?: InputMaybe<Scalars["Float"]>;
    containsi?: InputMaybe<Scalars["Float"]>;
    endsWith?: InputMaybe<Scalars["Float"]>;
    eq?: InputMaybe<Scalars["Float"]>;
    eqi?: InputMaybe<Scalars["Float"]>;
    gt?: InputMaybe<Scalars["Float"]>;
    gte?: InputMaybe<Scalars["Float"]>;
    in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
    lt?: InputMaybe<Scalars["Float"]>;
    lte?: InputMaybe<Scalars["Float"]>;
    ne?: InputMaybe<Scalars["Float"]>;
    not?: InputMaybe<FloatFilterInput>;
    notContains?: InputMaybe<Scalars["Float"]>;
    notContainsi?: InputMaybe<Scalars["Float"]>;
    notIn?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
    notNull?: InputMaybe<Scalars["Boolean"]>;
    null?: InputMaybe<Scalars["Boolean"]>;
    or?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
    startsWith?: InputMaybe<Scalars["Float"]>;
};

export type Footer = {
    __typename?: "Footer";
    createdAt?: Maybe<Scalars["DateTime"]>;
    link?: Maybe<Array<Maybe<ComponentLayoutLink>>>;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<FooterRelationResponseCollection>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    rights?: Maybe<Scalars["String"]>;
    slug?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FooterLinkArgs = {
    filters?: InputMaybe<ComponentLayoutLinkFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type FooterLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>;
};

export type FooterEntity = {
    __typename?: "FooterEntity";
    attributes?: Maybe<Footer>;
    id?: Maybe<Scalars["ID"]>;
};

export type FooterEntityResponse = {
    __typename?: "FooterEntityResponse";
    data?: Maybe<FooterEntity>;
};

export type FooterInput = {
    link?: InputMaybe<Array<InputMaybe<ComponentLayoutLinkInput>>>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
    rights?: InputMaybe<Scalars["String"]>;
    slug?: InputMaybe<Scalars["String"]>;
};

export type FooterRelationResponseCollection = {
    __typename?: "FooterRelationResponseCollection";
    data: Array<FooterEntity>;
};

export type GenericMorph =
    | About
    | App
    | ComponentLayoutApp
    | ComponentLayoutImageLink
    | ComponentLayoutInnputNumber
    | ComponentLayoutInputEmail
    | ComponentLayoutInputMessage
    | ComponentLayoutInputText
    | ComponentLayoutLink
    | ComponentLayoutSteps
    | ComponentSharedMetaSocial
    | ComponentSharedSeo
    | ContactForm
    | ContactMessage
    | ErrorPage
    | Footer
    | Header
    | Homepage
    | I18NLocale
    | Image
    | LayoutImage
    | Menu
    | Message
    | Service
    | Simulator
    | StaticPage
    | UploadFile
    | UploadFolder
    | UsersPermissionsPermission
    | UsersPermissionsRole
    | UsersPermissionsUser;

export type Header = {
    __typename?: "Header";
    account?: Maybe<Array<Maybe<ComponentLayoutLink>>>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    language?: Maybe<Scalars["String"]>;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<HeaderRelationResponseCollection>;
    logo?: Maybe<UploadFileEntityResponse>;
    menu?: Maybe<Array<Maybe<ComponentLayoutLink>>>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    slug?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type HeaderAccountArgs = {
    filters?: InputMaybe<ComponentLayoutLinkFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type HeaderLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>;
};

export type HeaderMenuArgs = {
    filters?: InputMaybe<ComponentLayoutLinkFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type HeaderEntity = {
    __typename?: "HeaderEntity";
    attributes?: Maybe<Header>;
    id?: Maybe<Scalars["ID"]>;
};

export type HeaderEntityResponse = {
    __typename?: "HeaderEntityResponse";
    data?: Maybe<HeaderEntity>;
};

export type HeaderInput = {
    account?: InputMaybe<Array<InputMaybe<ComponentLayoutLinkInput>>>;
    language?: InputMaybe<Scalars["String"]>;
    logo?: InputMaybe<Scalars["ID"]>;
    menu?: InputMaybe<Array<InputMaybe<ComponentLayoutLinkInput>>>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
    slug?: InputMaybe<Scalars["String"]>;
};

export type HeaderRelationResponseCollection = {
    __typename?: "HeaderRelationResponseCollection";
    data: Array<HeaderEntity>;
};

export type Homepage = {
    __typename?: "Homepage";
    contact?: Maybe<ComponentLayoutLink>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<HomepageRelationResponseCollection>;
    logo?: Maybe<UploadFileEntityResponse>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    seo?: Maybe<ComponentSharedSeo>;
    slug?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type HomepageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>;
};

export type HomepageEntity = {
    __typename?: "HomepageEntity";
    attributes?: Maybe<Homepage>;
    id?: Maybe<Scalars["ID"]>;
};

export type HomepageEntityResponse = {
    __typename?: "HomepageEntityResponse";
    data?: Maybe<HomepageEntity>;
};

export type HomepageInput = {
    contact?: InputMaybe<ComponentLayoutLinkInput>;
    description?: InputMaybe<Scalars["String"]>;
    logo?: InputMaybe<Scalars["ID"]>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
    seo?: InputMaybe<ComponentSharedSeoInput>;
    slug?: InputMaybe<Scalars["String"]>;
    title?: InputMaybe<Scalars["String"]>;
};

export type HomepageRelationResponseCollection = {
    __typename?: "HomepageRelationResponseCollection";
    data: Array<HomepageEntity>;
};

export type I18NLocale = {
    __typename?: "I18NLocale";
    code?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    name?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type I18NLocaleEntity = {
    __typename?: "I18NLocaleEntity";
    attributes?: Maybe<I18NLocale>;
    id?: Maybe<Scalars["ID"]>;
};

export type I18NLocaleEntityResponse = {
    __typename?: "I18NLocaleEntityResponse";
    data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
    __typename?: "I18NLocaleEntityResponseCollection";
    data: Array<I18NLocaleEntity>;
    meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
    code?: InputMaybe<StringFilterInput>;
    createdAt?: InputMaybe<DateTimeFilterInput>;
    id?: InputMaybe<IdFilterInput>;
    name?: InputMaybe<StringFilterInput>;
    not?: InputMaybe<I18NLocaleFiltersInput>;
    or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
    updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
    between?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
    contains?: InputMaybe<Scalars["ID"]>;
    containsi?: InputMaybe<Scalars["ID"]>;
    endsWith?: InputMaybe<Scalars["ID"]>;
    eq?: InputMaybe<Scalars["ID"]>;
    eqi?: InputMaybe<Scalars["ID"]>;
    gt?: InputMaybe<Scalars["ID"]>;
    gte?: InputMaybe<Scalars["ID"]>;
    in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
    lt?: InputMaybe<Scalars["ID"]>;
    lte?: InputMaybe<Scalars["ID"]>;
    ne?: InputMaybe<Scalars["ID"]>;
    not?: InputMaybe<IdFilterInput>;
    notContains?: InputMaybe<Scalars["ID"]>;
    notContainsi?: InputMaybe<Scalars["ID"]>;
    notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
    notNull?: InputMaybe<Scalars["Boolean"]>;
    null?: InputMaybe<Scalars["Boolean"]>;
    or?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
    startsWith?: InputMaybe<Scalars["ID"]>;
};

export type Image = {
    __typename?: "Image";
    createdAt?: Maybe<Scalars["DateTime"]>;
    images?: Maybe<UploadFileRelationResponseCollection>;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<ImageRelationResponseCollection>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    slug: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ImageImagesArgs = {
    filters?: InputMaybe<UploadFileFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ImageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>;
};

export type ImageEntity = {
    __typename?: "ImageEntity";
    attributes?: Maybe<Image>;
    id?: Maybe<Scalars["ID"]>;
};

export type ImageEntityResponse = {
    __typename?: "ImageEntityResponse";
    data?: Maybe<ImageEntity>;
};

export type ImageInput = {
    images?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
    slug?: InputMaybe<Scalars["String"]>;
};

export type ImageRelationResponseCollection = {
    __typename?: "ImageRelationResponseCollection";
    data: Array<ImageEntity>;
};

export type IntFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
    between?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
    contains?: InputMaybe<Scalars["Int"]>;
    containsi?: InputMaybe<Scalars["Int"]>;
    endsWith?: InputMaybe<Scalars["Int"]>;
    eq?: InputMaybe<Scalars["Int"]>;
    eqi?: InputMaybe<Scalars["Int"]>;
    gt?: InputMaybe<Scalars["Int"]>;
    gte?: InputMaybe<Scalars["Int"]>;
    in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
    lt?: InputMaybe<Scalars["Int"]>;
    lte?: InputMaybe<Scalars["Int"]>;
    ne?: InputMaybe<Scalars["Int"]>;
    not?: InputMaybe<IntFilterInput>;
    notContains?: InputMaybe<Scalars["Int"]>;
    notContainsi?: InputMaybe<Scalars["Int"]>;
    notIn?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
    notNull?: InputMaybe<Scalars["Boolean"]>;
    null?: InputMaybe<Scalars["Boolean"]>;
    or?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
    startsWith?: InputMaybe<Scalars["Int"]>;
};

export type JsonFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
    between?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
    contains?: InputMaybe<Scalars["JSON"]>;
    containsi?: InputMaybe<Scalars["JSON"]>;
    endsWith?: InputMaybe<Scalars["JSON"]>;
    eq?: InputMaybe<Scalars["JSON"]>;
    eqi?: InputMaybe<Scalars["JSON"]>;
    gt?: InputMaybe<Scalars["JSON"]>;
    gte?: InputMaybe<Scalars["JSON"]>;
    in?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
    lt?: InputMaybe<Scalars["JSON"]>;
    lte?: InputMaybe<Scalars["JSON"]>;
    ne?: InputMaybe<Scalars["JSON"]>;
    not?: InputMaybe<JsonFilterInput>;
    notContains?: InputMaybe<Scalars["JSON"]>;
    notContainsi?: InputMaybe<Scalars["JSON"]>;
    notIn?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
    notNull?: InputMaybe<Scalars["Boolean"]>;
    null?: InputMaybe<Scalars["Boolean"]>;
    or?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
    startsWith?: InputMaybe<Scalars["JSON"]>;
};

export type LayoutImage = {
    __typename?: "LayoutImage";
    createdAt?: Maybe<Scalars["DateTime"]>;
    image: UploadFileEntityResponse;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<LayoutImageRelationResponseCollection>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    slug?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type LayoutImageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>;
};

export type LayoutImageEntity = {
    __typename?: "LayoutImageEntity";
    attributes?: Maybe<LayoutImage>;
    id?: Maybe<Scalars["ID"]>;
};

export type LayoutImageEntityResponse = {
    __typename?: "LayoutImageEntityResponse";
    data?: Maybe<LayoutImageEntity>;
};

export type LayoutImageInput = {
    image?: InputMaybe<Scalars["ID"]>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
    slug?: InputMaybe<Scalars["String"]>;
};

export type LayoutImageRelationResponseCollection = {
    __typename?: "LayoutImageRelationResponseCollection";
    data: Array<LayoutImageEntity>;
};

export type Menu = {
    __typename?: "Menu";
    createdAt?: Maybe<Scalars["DateTime"]>;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<MenuRelationResponseCollection>;
    menuItems?: Maybe<Array<Maybe<ComponentLayoutLink>>>;
    name?: Maybe<Scalars["String"]>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MenuLocalizationsArgs = {
    filters?: InputMaybe<MenuFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    publicationState?: InputMaybe<PublicationState>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type MenuMenuItemsArgs = {
    filters?: InputMaybe<ComponentLayoutLinkFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type MenuEntity = {
    __typename?: "MenuEntity";
    attributes?: Maybe<Menu>;
    id?: Maybe<Scalars["ID"]>;
};

export type MenuEntityResponse = {
    __typename?: "MenuEntityResponse";
    data?: Maybe<MenuEntity>;
};

export type MenuEntityResponseCollection = {
    __typename?: "MenuEntityResponseCollection";
    data: Array<MenuEntity>;
    meta: ResponseCollectionMeta;
};

export type MenuFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<MenuFiltersInput>>>;
    createdAt?: InputMaybe<DateTimeFilterInput>;
    id?: InputMaybe<IdFilterInput>;
    locale?: InputMaybe<StringFilterInput>;
    localizations?: InputMaybe<MenuFiltersInput>;
    menuItems?: InputMaybe<ComponentLayoutLinkFiltersInput>;
    name?: InputMaybe<StringFilterInput>;
    not?: InputMaybe<MenuFiltersInput>;
    or?: InputMaybe<Array<InputMaybe<MenuFiltersInput>>>;
    publishedAt?: InputMaybe<DateTimeFilterInput>;
    updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type MenuInput = {
    menuItems?: InputMaybe<Array<InputMaybe<ComponentLayoutLinkInput>>>;
    name?: InputMaybe<Scalars["String"]>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MenuRelationResponseCollection = {
    __typename?: "MenuRelationResponseCollection";
    data: Array<MenuEntity>;
};

export type Message = {
    __typename?: "Message";
    createdAt?: Maybe<Scalars["DateTime"]>;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<MessageRelationResponseCollection>;
    messages?: Maybe<Scalars["JSON"]>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MessageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>;
};

export type MessageEntity = {
    __typename?: "MessageEntity";
    attributes?: Maybe<Message>;
    id?: Maybe<Scalars["ID"]>;
};

export type MessageEntityResponse = {
    __typename?: "MessageEntityResponse";
    data?: Maybe<MessageEntity>;
};

export type MessageInput = {
    messages?: InputMaybe<Scalars["JSON"]>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MessageRelationResponseCollection = {
    __typename?: "MessageRelationResponseCollection";
    data: Array<MessageEntity>;
};

export type Mutation = {
    __typename?: "Mutation";
    /** Change user password. Confirm with the current password. */
    changePassword?: Maybe<UsersPermissionsLoginPayload>;
    createAboutLocalization?: Maybe<AboutEntityResponse>;
    createAppLocalization?: Maybe<AppEntityResponse>;
    createContactFormLocalization?: Maybe<ContactFormEntityResponse>;
    createContactMessage?: Maybe<ContactMessageEntityResponse>;
    createContactMessageLocalization?: Maybe<ContactMessageEntityResponse>;
    createErrorPageLocalization?: Maybe<ErrorPageEntityResponse>;
    createFooterLocalization?: Maybe<FooterEntityResponse>;
    createHeaderLocalization?: Maybe<HeaderEntityResponse>;
    createHomepageLocalization?: Maybe<HomepageEntityResponse>;
    createImageLocalization?: Maybe<ImageEntityResponse>;
    createLayoutImageLocalization?: Maybe<LayoutImageEntityResponse>;
    createMenu?: Maybe<MenuEntityResponse>;
    createMenuLocalization?: Maybe<MenuEntityResponse>;
    createMessageLocalization?: Maybe<MessageEntityResponse>;
    createServiceLocalization?: Maybe<ServiceEntityResponse>;
    createSimulatorLocalization?: Maybe<SimulatorEntityResponse>;
    createStaticPage?: Maybe<StaticPageEntityResponse>;
    createStaticPageLocalization?: Maybe<StaticPageEntityResponse>;
    createUploadFile?: Maybe<UploadFileEntityResponse>;
    createUploadFolder?: Maybe<UploadFolderEntityResponse>;
    /** Create a new role */
    createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
    /** Create a new user */
    createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
    deleteAbout?: Maybe<AboutEntityResponse>;
    deleteApp?: Maybe<AppEntityResponse>;
    deleteContactForm?: Maybe<ContactFormEntityResponse>;
    deleteContactMessage?: Maybe<ContactMessageEntityResponse>;
    deleteErrorPage?: Maybe<ErrorPageEntityResponse>;
    deleteFooter?: Maybe<FooterEntityResponse>;
    deleteHeader?: Maybe<HeaderEntityResponse>;
    deleteHomepage?: Maybe<HomepageEntityResponse>;
    deleteImage?: Maybe<ImageEntityResponse>;
    deleteLayoutImage?: Maybe<LayoutImageEntityResponse>;
    deleteMenu?: Maybe<MenuEntityResponse>;
    deleteMessage?: Maybe<MessageEntityResponse>;
    deleteService?: Maybe<ServiceEntityResponse>;
    deleteSimulator?: Maybe<SimulatorEntityResponse>;
    deleteStaticPage?: Maybe<StaticPageEntityResponse>;
    deleteUploadFile?: Maybe<UploadFileEntityResponse>;
    deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
    /** Delete an existing role */
    deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
    /** Delete an existing user */
    deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
    /** Confirm an email users email address */
    emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
    /** Request a reset password token */
    forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
    login: UsersPermissionsLoginPayload;
    multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
    /** Register a user */
    register: UsersPermissionsLoginPayload;
    removeFile?: Maybe<UploadFileEntityResponse>;
    /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
    resetPassword?: Maybe<UsersPermissionsLoginPayload>;
    updateAbout?: Maybe<AboutEntityResponse>;
    updateApp?: Maybe<AppEntityResponse>;
    updateContactForm?: Maybe<ContactFormEntityResponse>;
    updateContactMessage?: Maybe<ContactMessageEntityResponse>;
    updateErrorPage?: Maybe<ErrorPageEntityResponse>;
    updateFileInfo: UploadFileEntityResponse;
    updateFooter?: Maybe<FooterEntityResponse>;
    updateHeader?: Maybe<HeaderEntityResponse>;
    updateHomepage?: Maybe<HomepageEntityResponse>;
    updateImage?: Maybe<ImageEntityResponse>;
    updateLayoutImage?: Maybe<LayoutImageEntityResponse>;
    updateMenu?: Maybe<MenuEntityResponse>;
    updateMessage?: Maybe<MessageEntityResponse>;
    updateService?: Maybe<ServiceEntityResponse>;
    updateSimulator?: Maybe<SimulatorEntityResponse>;
    updateStaticPage?: Maybe<StaticPageEntityResponse>;
    updateUploadFile?: Maybe<UploadFileEntityResponse>;
    updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
    /** Update an existing role */
    updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
    /** Update an existing user */
    updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
    upload: UploadFileEntityResponse;
};

export type MutationChangePasswordArgs = {
    currentPassword: Scalars["String"];
    password: Scalars["String"];
    passwordConfirmation: Scalars["String"];
};

export type MutationCreateAboutLocalizationArgs = {
    data?: InputMaybe<AboutInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateAppLocalizationArgs = {
    data?: InputMaybe<AppInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateContactFormLocalizationArgs = {
    data?: InputMaybe<ContactFormInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateContactMessageArgs = {
    data: ContactMessageInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateContactMessageLocalizationArgs = {
    data?: InputMaybe<ContactMessageInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateErrorPageLocalizationArgs = {
    data?: InputMaybe<ErrorPageInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateFooterLocalizationArgs = {
    data?: InputMaybe<FooterInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateHeaderLocalizationArgs = {
    data?: InputMaybe<HeaderInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateHomepageLocalizationArgs = {
    data?: InputMaybe<HomepageInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateImageLocalizationArgs = {
    data?: InputMaybe<ImageInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateLayoutImageLocalizationArgs = {
    data?: InputMaybe<LayoutImageInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateMenuArgs = {
    data: MenuInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateMenuLocalizationArgs = {
    data?: InputMaybe<MenuInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateMessageLocalizationArgs = {
    data?: InputMaybe<MessageInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateServiceLocalizationArgs = {
    data?: InputMaybe<ServiceInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateSimulatorLocalizationArgs = {
    data?: InputMaybe<SimulatorInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateStaticPageArgs = {
    data: StaticPageInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateStaticPageLocalizationArgs = {
    data?: InputMaybe<StaticPageInput>;
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationCreateUploadFileArgs = {
    data: UploadFileInput;
};

export type MutationCreateUploadFolderArgs = {
    data: UploadFolderInput;
};

export type MutationCreateUsersPermissionsRoleArgs = {
    data: UsersPermissionsRoleInput;
};

export type MutationCreateUsersPermissionsUserArgs = {
    data: UsersPermissionsUserInput;
};

export type MutationDeleteAboutArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteAppArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteContactFormArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteContactMessageArgs = {
    id: Scalars["ID"];
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteErrorPageArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteFooterArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteHeaderArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteHomepageArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteImageArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteLayoutImageArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteMenuArgs = {
    id: Scalars["ID"];
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteMessageArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteServiceArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteSimulatorArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteStaticPageArgs = {
    id: Scalars["ID"];
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationDeleteUploadFileArgs = {
    id: Scalars["ID"];
};

export type MutationDeleteUploadFolderArgs = {
    id: Scalars["ID"];
};

export type MutationDeleteUsersPermissionsRoleArgs = {
    id: Scalars["ID"];
};

export type MutationDeleteUsersPermissionsUserArgs = {
    id: Scalars["ID"];
};

export type MutationEmailConfirmationArgs = {
    confirmation: Scalars["String"];
};

export type MutationForgotPasswordArgs = {
    email: Scalars["String"];
};

export type MutationLoginArgs = {
    input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
    field?: InputMaybe<Scalars["String"]>;
    files: Array<InputMaybe<Scalars["Upload"]>>;
    ref?: InputMaybe<Scalars["String"]>;
    refId?: InputMaybe<Scalars["ID"]>;
};

export type MutationRegisterArgs = {
    input: UsersPermissionsRegisterInput;
};

export type MutationRemoveFileArgs = {
    id: Scalars["ID"];
};

export type MutationResetPasswordArgs = {
    code: Scalars["String"];
    password: Scalars["String"];
    passwordConfirmation: Scalars["String"];
};

export type MutationUpdateAboutArgs = {
    data: AboutInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateAppArgs = {
    data: AppInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateContactFormArgs = {
    data: ContactFormInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateContactMessageArgs = {
    data: ContactMessageInput;
    id: Scalars["ID"];
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateErrorPageArgs = {
    data: ErrorPageInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateFileInfoArgs = {
    id: Scalars["ID"];
    info?: InputMaybe<FileInfoInput>;
};

export type MutationUpdateFooterArgs = {
    data: FooterInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateHeaderArgs = {
    data: HeaderInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateHomepageArgs = {
    data: HomepageInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateImageArgs = {
    data: ImageInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateLayoutImageArgs = {
    data: LayoutImageInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateMenuArgs = {
    data: MenuInput;
    id: Scalars["ID"];
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateMessageArgs = {
    data: MessageInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateServiceArgs = {
    data: ServiceInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateSimulatorArgs = {
    data: SimulatorInput;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateStaticPageArgs = {
    data: StaticPageInput;
    id: Scalars["ID"];
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type MutationUpdateUploadFileArgs = {
    data: UploadFileInput;
    id: Scalars["ID"];
};

export type MutationUpdateUploadFolderArgs = {
    data: UploadFolderInput;
    id: Scalars["ID"];
};

export type MutationUpdateUsersPermissionsRoleArgs = {
    data: UsersPermissionsRoleInput;
    id: Scalars["ID"];
};

export type MutationUpdateUsersPermissionsUserArgs = {
    data: UsersPermissionsUserInput;
    id: Scalars["ID"];
};

export type MutationUploadArgs = {
    field?: InputMaybe<Scalars["String"]>;
    file: Scalars["Upload"];
    info?: InputMaybe<FileInfoInput>;
    ref?: InputMaybe<Scalars["String"]>;
    refId?: InputMaybe<Scalars["ID"]>;
};

export type Pagination = {
    __typename?: "Pagination";
    page: Scalars["Int"];
    pageCount: Scalars["Int"];
    pageSize: Scalars["Int"];
    total: Scalars["Int"];
};

export type PaginationArg = {
    limit?: InputMaybe<Scalars["Int"]>;
    page?: InputMaybe<Scalars["Int"]>;
    pageSize?: InputMaybe<Scalars["Int"]>;
    start?: InputMaybe<Scalars["Int"]>;
};

export enum PublicationState {
    Live = "LIVE",
    Preview = "PREVIEW",
}

export type Query = {
    __typename?: "Query";
    about?: Maybe<AboutEntityResponse>;
    app?: Maybe<AppEntityResponse>;
    contactForm?: Maybe<ContactFormEntityResponse>;
    contactMessage?: Maybe<ContactMessageEntityResponse>;
    contactMessages?: Maybe<ContactMessageEntityResponseCollection>;
    errorPage?: Maybe<ErrorPageEntityResponse>;
    footer?: Maybe<FooterEntityResponse>;
    header?: Maybe<HeaderEntityResponse>;
    homepage?: Maybe<HomepageEntityResponse>;
    i18NLocale?: Maybe<I18NLocaleEntityResponse>;
    i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
    image?: Maybe<ImageEntityResponse>;
    layoutImage?: Maybe<LayoutImageEntityResponse>;
    me?: Maybe<UsersPermissionsMe>;
    menu?: Maybe<MenuEntityResponse>;
    menus?: Maybe<MenuEntityResponseCollection>;
    message?: Maybe<MessageEntityResponse>;
    service?: Maybe<ServiceEntityResponse>;
    simulator?: Maybe<SimulatorEntityResponse>;
    staticPage?: Maybe<StaticPageEntityResponse>;
    staticPages?: Maybe<StaticPageEntityResponseCollection>;
    uploadFile?: Maybe<UploadFileEntityResponse>;
    uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
    uploadFolder?: Maybe<UploadFolderEntityResponse>;
    uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
    usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
    usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
    usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
    usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
};

export type QueryAboutArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    publicationState?: InputMaybe<PublicationState>;
};

export type QueryAppArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    publicationState?: InputMaybe<PublicationState>;
};

export type QueryContactFormArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    publicationState?: InputMaybe<PublicationState>;
};

export type QueryContactMessageArgs = {
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type QueryContactMessagesArgs = {
    filters?: InputMaybe<ContactMessageFiltersInput>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    pagination?: InputMaybe<PaginationArg>;
    publicationState?: InputMaybe<PublicationState>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryErrorPageArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    publicationState?: InputMaybe<PublicationState>;
};

export type QueryFooterArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    publicationState?: InputMaybe<PublicationState>;
};

export type QueryHeaderArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    publicationState?: InputMaybe<PublicationState>;
};

export type QueryHomepageArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    publicationState?: InputMaybe<PublicationState>;
};

export type QueryI18NLocaleArgs = {
    id?: InputMaybe<Scalars["ID"]>;
};

export type QueryI18NLocalesArgs = {
    filters?: InputMaybe<I18NLocaleFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryImageArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    publicationState?: InputMaybe<PublicationState>;
};

export type QueryLayoutImageArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    publicationState?: InputMaybe<PublicationState>;
};

export type QueryMenuArgs = {
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type QueryMenusArgs = {
    filters?: InputMaybe<MenuFiltersInput>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    pagination?: InputMaybe<PaginationArg>;
    publicationState?: InputMaybe<PublicationState>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryMessageArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    publicationState?: InputMaybe<PublicationState>;
};

export type QueryServiceArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    publicationState?: InputMaybe<PublicationState>;
};

export type QuerySimulatorArgs = {
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    publicationState?: InputMaybe<PublicationState>;
};

export type QueryStaticPageArgs = {
    id?: InputMaybe<Scalars["ID"]>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
};

export type QueryStaticPagesArgs = {
    filters?: InputMaybe<StaticPageFiltersInput>;
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
    pagination?: InputMaybe<PaginationArg>;
    publicationState?: InputMaybe<PublicationState>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryUploadFileArgs = {
    id?: InputMaybe<Scalars["ID"]>;
};

export type QueryUploadFilesArgs = {
    filters?: InputMaybe<UploadFileFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryUploadFolderArgs = {
    id?: InputMaybe<Scalars["ID"]>;
};

export type QueryUploadFoldersArgs = {
    filters?: InputMaybe<UploadFolderFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryUsersPermissionsRoleArgs = {
    id?: InputMaybe<Scalars["ID"]>;
};

export type QueryUsersPermissionsRolesArgs = {
    filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryUsersPermissionsUserArgs = {
    id?: InputMaybe<Scalars["ID"]>;
};

export type QueryUsersPermissionsUsersArgs = {
    filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ResponseCollectionMeta = {
    __typename?: "ResponseCollectionMeta";
    pagination: Pagination;
};

export type Service = {
    __typename?: "Service";
    button?: Maybe<ComponentLayoutLink>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    image?: Maybe<UploadFileEntityResponse>;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<ServiceRelationResponseCollection>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    slug?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ServiceLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>;
};

export type ServiceEntity = {
    __typename?: "ServiceEntity";
    attributes?: Maybe<Service>;
    id?: Maybe<Scalars["ID"]>;
};

export type ServiceEntityResponse = {
    __typename?: "ServiceEntityResponse";
    data?: Maybe<ServiceEntity>;
};

export type ServiceInput = {
    button?: InputMaybe<ComponentLayoutLinkInput>;
    description?: InputMaybe<Scalars["String"]>;
    image?: InputMaybe<Scalars["ID"]>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
    slug?: InputMaybe<Scalars["String"]>;
    title?: InputMaybe<Scalars["String"]>;
};

export type ServiceRelationResponseCollection = {
    __typename?: "ServiceRelationResponseCollection";
    data: Array<ServiceEntity>;
};

export type Simulator = {
    __typename?: "Simulator";
    content?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<SimulatorRelationResponseCollection>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    slug?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SimulatorLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>;
};

export type SimulatorEntity = {
    __typename?: "SimulatorEntity";
    attributes?: Maybe<Simulator>;
    id?: Maybe<Scalars["ID"]>;
};

export type SimulatorEntityResponse = {
    __typename?: "SimulatorEntityResponse";
    data?: Maybe<SimulatorEntity>;
};

export type SimulatorInput = {
    content?: InputMaybe<Scalars["String"]>;
    description?: InputMaybe<Scalars["String"]>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
    slug?: InputMaybe<Scalars["String"]>;
    title?: InputMaybe<Scalars["String"]>;
};

export type SimulatorRelationResponseCollection = {
    __typename?: "SimulatorRelationResponseCollection";
    data: Array<SimulatorEntity>;
};

export type StaticPage = {
    __typename?: "StaticPage";
    content?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    locale?: Maybe<Scalars["String"]>;
    localizations?: Maybe<StaticPageRelationResponseCollection>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    slug?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type StaticPageLocalizationsArgs = {
    filters?: InputMaybe<StaticPageFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    publicationState?: InputMaybe<PublicationState>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type StaticPageEntity = {
    __typename?: "StaticPageEntity";
    attributes?: Maybe<StaticPage>;
    id?: Maybe<Scalars["ID"]>;
};

export type StaticPageEntityResponse = {
    __typename?: "StaticPageEntityResponse";
    data?: Maybe<StaticPageEntity>;
};

export type StaticPageEntityResponseCollection = {
    __typename?: "StaticPageEntityResponseCollection";
    data: Array<StaticPageEntity>;
    meta: ResponseCollectionMeta;
};

export type StaticPageFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<StaticPageFiltersInput>>>;
    content?: InputMaybe<StringFilterInput>;
    createdAt?: InputMaybe<DateTimeFilterInput>;
    id?: InputMaybe<IdFilterInput>;
    locale?: InputMaybe<StringFilterInput>;
    localizations?: InputMaybe<StaticPageFiltersInput>;
    not?: InputMaybe<StaticPageFiltersInput>;
    or?: InputMaybe<Array<InputMaybe<StaticPageFiltersInput>>>;
    publishedAt?: InputMaybe<DateTimeFilterInput>;
    slug?: InputMaybe<StringFilterInput>;
    title?: InputMaybe<StringFilterInput>;
    updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type StaticPageInput = {
    content?: InputMaybe<Scalars["String"]>;
    publishedAt?: InputMaybe<Scalars["DateTime"]>;
    slug?: InputMaybe<Scalars["String"]>;
    title?: InputMaybe<Scalars["String"]>;
};

export type StaticPageRelationResponseCollection = {
    __typename?: "StaticPageRelationResponseCollection";
    data: Array<StaticPageEntity>;
};

export type StringFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    between?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    contains?: InputMaybe<Scalars["String"]>;
    containsi?: InputMaybe<Scalars["String"]>;
    endsWith?: InputMaybe<Scalars["String"]>;
    eq?: InputMaybe<Scalars["String"]>;
    eqi?: InputMaybe<Scalars["String"]>;
    gt?: InputMaybe<Scalars["String"]>;
    gte?: InputMaybe<Scalars["String"]>;
    in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    lt?: InputMaybe<Scalars["String"]>;
    lte?: InputMaybe<Scalars["String"]>;
    ne?: InputMaybe<Scalars["String"]>;
    not?: InputMaybe<StringFilterInput>;
    notContains?: InputMaybe<Scalars["String"]>;
    notContainsi?: InputMaybe<Scalars["String"]>;
    notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    notNull?: InputMaybe<Scalars["Boolean"]>;
    null?: InputMaybe<Scalars["Boolean"]>;
    or?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    startsWith?: InputMaybe<Scalars["String"]>;
};

export type UploadFile = {
    __typename?: "UploadFile";
    alternativeText?: Maybe<Scalars["String"]>;
    caption?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    ext?: Maybe<Scalars["String"]>;
    formats?: Maybe<Scalars["JSON"]>;
    hash: Scalars["String"];
    height?: Maybe<Scalars["Int"]>;
    mime: Scalars["String"];
    name: Scalars["String"];
    previewUrl?: Maybe<Scalars["String"]>;
    provider: Scalars["String"];
    provider_metadata?: Maybe<Scalars["JSON"]>;
    related?: Maybe<Array<Maybe<GenericMorph>>>;
    size: Scalars["Float"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
    url: Scalars["String"];
    width?: Maybe<Scalars["Int"]>;
};

export type UploadFileEntity = {
    __typename?: "UploadFileEntity";
    attributes?: Maybe<UploadFile>;
    id?: Maybe<Scalars["ID"]>;
};

export type UploadFileEntityResponse = {
    __typename?: "UploadFileEntityResponse";
    data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
    __typename?: "UploadFileEntityResponseCollection";
    data: Array<UploadFileEntity>;
    meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
    alternativeText?: InputMaybe<StringFilterInput>;
    and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
    caption?: InputMaybe<StringFilterInput>;
    createdAt?: InputMaybe<DateTimeFilterInput>;
    ext?: InputMaybe<StringFilterInput>;
    folder?: InputMaybe<UploadFolderFiltersInput>;
    folderPath?: InputMaybe<StringFilterInput>;
    formats?: InputMaybe<JsonFilterInput>;
    hash?: InputMaybe<StringFilterInput>;
    height?: InputMaybe<IntFilterInput>;
    id?: InputMaybe<IdFilterInput>;
    mime?: InputMaybe<StringFilterInput>;
    name?: InputMaybe<StringFilterInput>;
    not?: InputMaybe<UploadFileFiltersInput>;
    or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
    previewUrl?: InputMaybe<StringFilterInput>;
    provider?: InputMaybe<StringFilterInput>;
    provider_metadata?: InputMaybe<JsonFilterInput>;
    size?: InputMaybe<FloatFilterInput>;
    updatedAt?: InputMaybe<DateTimeFilterInput>;
    url?: InputMaybe<StringFilterInput>;
    width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
    alternativeText?: InputMaybe<Scalars["String"]>;
    caption?: InputMaybe<Scalars["String"]>;
    ext?: InputMaybe<Scalars["String"]>;
    folder?: InputMaybe<Scalars["ID"]>;
    folderPath?: InputMaybe<Scalars["String"]>;
    formats?: InputMaybe<Scalars["JSON"]>;
    hash?: InputMaybe<Scalars["String"]>;
    height?: InputMaybe<Scalars["Int"]>;
    mime?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    previewUrl?: InputMaybe<Scalars["String"]>;
    provider?: InputMaybe<Scalars["String"]>;
    provider_metadata?: InputMaybe<Scalars["JSON"]>;
    size?: InputMaybe<Scalars["Float"]>;
    url?: InputMaybe<Scalars["String"]>;
    width?: InputMaybe<Scalars["Int"]>;
};

export type UploadFileRelationResponseCollection = {
    __typename?: "UploadFileRelationResponseCollection";
    data: Array<UploadFileEntity>;
};

export type UploadFolder = {
    __typename?: "UploadFolder";
    children?: Maybe<UploadFolderRelationResponseCollection>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    files?: Maybe<UploadFileRelationResponseCollection>;
    name: Scalars["String"];
    parent?: Maybe<UploadFolderEntityResponse>;
    path: Scalars["String"];
    pathId: Scalars["Int"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type UploadFolderChildrenArgs = {
    filters?: InputMaybe<UploadFolderFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UploadFolderFilesArgs = {
    filters?: InputMaybe<UploadFileFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UploadFolderEntity = {
    __typename?: "UploadFolderEntity";
    attributes?: Maybe<UploadFolder>;
    id?: Maybe<Scalars["ID"]>;
};

export type UploadFolderEntityResponse = {
    __typename?: "UploadFolderEntityResponse";
    data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
    __typename?: "UploadFolderEntityResponseCollection";
    data: Array<UploadFolderEntity>;
    meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
    children?: InputMaybe<UploadFolderFiltersInput>;
    createdAt?: InputMaybe<DateTimeFilterInput>;
    files?: InputMaybe<UploadFileFiltersInput>;
    id?: InputMaybe<IdFilterInput>;
    name?: InputMaybe<StringFilterInput>;
    not?: InputMaybe<UploadFolderFiltersInput>;
    or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
    parent?: InputMaybe<UploadFolderFiltersInput>;
    path?: InputMaybe<StringFilterInput>;
    pathId?: InputMaybe<IntFilterInput>;
    updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
    children?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
    files?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
    name?: InputMaybe<Scalars["String"]>;
    parent?: InputMaybe<Scalars["ID"]>;
    path?: InputMaybe<Scalars["String"]>;
    pathId?: InputMaybe<Scalars["Int"]>;
};

export type UploadFolderRelationResponseCollection = {
    __typename?: "UploadFolderRelationResponseCollection";
    data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
    __typename?: "UsersPermissionsCreateRolePayload";
    ok: Scalars["Boolean"];
};

export type UsersPermissionsDeleteRolePayload = {
    __typename?: "UsersPermissionsDeleteRolePayload";
    ok: Scalars["Boolean"];
};

export type UsersPermissionsLoginInput = {
    identifier: Scalars["String"];
    password: Scalars["String"];
    provider?: Scalars["String"];
};

export type UsersPermissionsLoginPayload = {
    __typename?: "UsersPermissionsLoginPayload";
    jwt?: Maybe<Scalars["String"]>;
    user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
    __typename?: "UsersPermissionsMe";
    blocked?: Maybe<Scalars["Boolean"]>;
    confirmed?: Maybe<Scalars["Boolean"]>;
    email?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    role?: Maybe<UsersPermissionsMeRole>;
    username: Scalars["String"];
};

export type UsersPermissionsMeRole = {
    __typename?: "UsersPermissionsMeRole";
    description?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    name: Scalars["String"];
    type?: Maybe<Scalars["String"]>;
};

export type UsersPermissionsPasswordPayload = {
    __typename?: "UsersPermissionsPasswordPayload";
    ok: Scalars["Boolean"];
};

export type UsersPermissionsPermission = {
    __typename?: "UsersPermissionsPermission";
    action: Scalars["String"];
    createdAt?: Maybe<Scalars["DateTime"]>;
    role?: Maybe<UsersPermissionsRoleEntityResponse>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type UsersPermissionsPermissionEntity = {
    __typename?: "UsersPermissionsPermissionEntity";
    attributes?: Maybe<UsersPermissionsPermission>;
    id?: Maybe<Scalars["ID"]>;
};

export type UsersPermissionsPermissionFiltersInput = {
    action?: InputMaybe<StringFilterInput>;
    and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
    createdAt?: InputMaybe<DateTimeFilterInput>;
    id?: InputMaybe<IdFilterInput>;
    not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
    or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
    role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
    updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
    __typename?: "UsersPermissionsPermissionRelationResponseCollection";
    data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
    email: Scalars["String"];
    password: Scalars["String"];
    username: Scalars["String"];
};

export type UsersPermissionsRole = {
    __typename?: "UsersPermissionsRole";
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
    type?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};

export type UsersPermissionsRolePermissionsArgs = {
    filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UsersPermissionsRoleUsersArgs = {
    filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
    pagination?: InputMaybe<PaginationArg>;
    sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UsersPermissionsRoleEntity = {
    __typename?: "UsersPermissionsRoleEntity";
    attributes?: Maybe<UsersPermissionsRole>;
    id?: Maybe<Scalars["ID"]>;
};

export type UsersPermissionsRoleEntityResponse = {
    __typename?: "UsersPermissionsRoleEntityResponse";
    data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
    __typename?: "UsersPermissionsRoleEntityResponseCollection";
    data: Array<UsersPermissionsRoleEntity>;
    meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
    createdAt?: InputMaybe<DateTimeFilterInput>;
    description?: InputMaybe<StringFilterInput>;
    id?: InputMaybe<IdFilterInput>;
    name?: InputMaybe<StringFilterInput>;
    not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
    or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
    permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
    type?: InputMaybe<StringFilterInput>;
    updatedAt?: InputMaybe<DateTimeFilterInput>;
    users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
    description?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    permissions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
    type?: InputMaybe<Scalars["String"]>;
    users?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type UsersPermissionsUpdateRolePayload = {
    __typename?: "UsersPermissionsUpdateRolePayload";
    ok: Scalars["Boolean"];
};

export type UsersPermissionsUser = {
    __typename?: "UsersPermissionsUser";
    blocked?: Maybe<Scalars["Boolean"]>;
    confirmed?: Maybe<Scalars["Boolean"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    provider?: Maybe<Scalars["String"]>;
    role?: Maybe<UsersPermissionsRoleEntityResponse>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    username: Scalars["String"];
};

export type UsersPermissionsUserEntity = {
    __typename?: "UsersPermissionsUserEntity";
    attributes?: Maybe<UsersPermissionsUser>;
    id?: Maybe<Scalars["ID"]>;
};

export type UsersPermissionsUserEntityResponse = {
    __typename?: "UsersPermissionsUserEntityResponse";
    data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
    __typename?: "UsersPermissionsUserEntityResponseCollection";
    data: Array<UsersPermissionsUserEntity>;
    meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
    blocked?: InputMaybe<BooleanFilterInput>;
    confirmationToken?: InputMaybe<StringFilterInput>;
    confirmed?: InputMaybe<BooleanFilterInput>;
    createdAt?: InputMaybe<DateTimeFilterInput>;
    email?: InputMaybe<StringFilterInput>;
    id?: InputMaybe<IdFilterInput>;
    not?: InputMaybe<UsersPermissionsUserFiltersInput>;
    or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
    password?: InputMaybe<StringFilterInput>;
    provider?: InputMaybe<StringFilterInput>;
    resetPasswordToken?: InputMaybe<StringFilterInput>;
    role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
    updatedAt?: InputMaybe<DateTimeFilterInput>;
    username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
    blocked?: InputMaybe<Scalars["Boolean"]>;
    confirmationToken?: InputMaybe<Scalars["String"]>;
    confirmed?: InputMaybe<Scalars["Boolean"]>;
    email?: InputMaybe<Scalars["String"]>;
    password?: InputMaybe<Scalars["String"]>;
    provider?: InputMaybe<Scalars["String"]>;
    resetPasswordToken?: InputMaybe<Scalars["String"]>;
    role?: InputMaybe<Scalars["ID"]>;
    username?: InputMaybe<Scalars["String"]>;
};

export type UsersPermissionsUserRelationResponseCollection = {
    __typename?: "UsersPermissionsUserRelationResponseCollection";
    data: Array<UsersPermissionsUserEntity>;
};

export type ErrorPageQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
}>;

export type ErrorPageQuery = {
    __typename?: "Query";
    errorPage?: {
        __typename?: "ErrorPageEntityResponse";
        data?: {
            __typename?: "ErrorPageEntity";
            attributes?: {
                __typename?: "ErrorPage";
                slug?: string | null;
                title?: string | null;
                subtitle?: string | null;
                description?: string | null;
                button?: {
                    __typename?: "ComponentLayoutLink";
                    id: string;
                    label: string;
                    url?: string | null;
                } | null;
            } | null;
        } | null;
    } | null;
};

export type FooterQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
}>;

export type FooterQuery = {
    __typename?: "Query";
    footer?: {
        __typename?: "FooterEntityResponse";
        data?: {
            __typename?: "FooterEntity";
            attributes?: {
                __typename?: "Footer";
                slug?: string | null;
                rights?: string | null;
                link?: Array<{
                    __typename?: "ComponentLayoutLink";
                    id: string;
                    label: string;
                    url?: string | null;
                } | null> | null;
            } | null;
        } | null;
    } | null;
};

export type HeaderQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
}>;

export type HeaderQuery = {
    __typename?: "Query";
    header?: {
        __typename?: "HeaderEntityResponse";
        data?: {
            __typename?: "HeaderEntity";
            id?: string | null;
            attributes?: {
                __typename?: "Header";
                slug?: string | null;
                language?: string | null;
                logo?: {
                    __typename: "UploadFileEntityResponse";
                    data?: {
                        __typename?: "UploadFileEntity";
                        id?: string | null;
                        attributes?: {
                            __typename?: "UploadFile";
                            name: string;
                            alternativeText?: string | null;
                            url: string;
                            previewUrl?: string | null;
                        } | null;
                    } | null;
                } | null;
                menu?: Array<{
                    __typename: "ComponentLayoutLink";
                    id: string;
                    label: string;
                    url?: string | null;
                } | null> | null;
                account?: Array<{
                    __typename: "ComponentLayoutLink";
                    id: string;
                    label: string;
                    url?: string | null;
                } | null> | null;
            } | null;
        } | null;
    } | null;
};

export type AboutQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
}>;

export type AboutQuery = {
    __typename?: "Query";
    about?: {
        __typename?: "AboutEntityResponse";
        data?: {
            __typename?: "AboutEntity";
            id?: string | null;
            attributes?: {
                __typename?: "About";
                slug?: string | null;
                title?: string | null;
                description?: string | null;
                steps?: Array<{
                    __typename?: "ComponentLayoutSteps";
                    id: string;
                    title?: string | null;
                    description?: string | null;
                    image?: {
                        __typename?: "UploadFileEntityResponse";
                        data?: {
                            __typename?: "UploadFileEntity";
                            id?: string | null;
                            attributes?: {
                                __typename?: "UploadFile";
                                name: string;
                                url: string;
                            } | null;
                        } | null;
                    } | null;
                } | null> | null;
            } | null;
        } | null;
    } | null;
};

export type AppQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
}>;

export type AppQuery = {
    __typename?: "Query";
    app?: {
        __typename?: "AppEntityResponse";
        data?: {
            __typename?: "AppEntity";
            attributes?: {
                __typename?: "App";
                slug?: string | null;
                title?: string | null;
                description?: string | null;
                image?: {
                    __typename?: "UploadFileEntityResponse";
                    data?: {
                        __typename?: "UploadFileEntity";
                        attributes?: {
                            __typename?: "UploadFile";
                            name: string;
                            url: string;
                        } | null;
                    } | null;
                } | null;
                button?: {
                    __typename?: "ComponentLayoutLink";
                    label: string;
                    url?: string | null;
                } | null;
                applinks?: {
                    __typename?: "ComponentLayoutApp";
                    title?: string | null;
                    imagelink?: Array<{
                        __typename?: "ComponentLayoutImageLink";
                        id: string;
                        name?: string | null;
                        url?: string | null;
                        image?: {
                            __typename?: "UploadFileEntityResponse";
                            data?: {
                                __typename?: "UploadFileEntity";
                                attributes?: {
                                    __typename?: "UploadFile";
                                    name: string;
                                    url: string;
                                } | null;
                            } | null;
                        } | null;
                    } | null> | null;
                } | null;
            } | null;
        } | null;
    } | null;
};

export type ContactFormQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
}>;

export type ContactFormQuery = {
    __typename?: "Query";
    contactForm?: {
        __typename?: "ContactFormEntityResponse";
        data?: {
            __typename?: "ContactFormEntity";
            id?: string | null;
            attributes?: {
                __typename?: "ContactForm";
                slug?: string | null;
                title?: string | null;
                terms?: string | null;
                button?: {
                    __typename?: "ComponentLayoutLink";
                    label: string;
                } | null;
                name?: {
                    __typename?: "ComponentLayoutInputText";
                    label?: string | null;
                } | null;
                email?: {
                    __typename?: "ComponentLayoutInputEmail";
                    label?: string | null;
                } | null;
                phone?: {
                    __typename?: "ComponentLayoutInnputNumber";
                    label?: string | null;
                } | null;
                message?: {
                    __typename?: "ComponentLayoutInputMessage";
                    label?: string | null;
                } | null;
            } | null;
        } | null;
    } | null;
};

export type CreateContactMessageMutationVariables = Exact<{
    name?: InputMaybe<Scalars["String"]>;
    email?: InputMaybe<Scalars["String"]>;
    phone?: InputMaybe<Scalars["String"]>;
    message?: InputMaybe<Scalars["String"]>;
    recaptchaResponse?: InputMaybe<Scalars["String"]>;
}>;

export type CreateContactMessageMutation = {
    __typename?: "Mutation";
    createContactMessage?: {
        __typename?: "ContactMessageEntityResponse";
        data?: {
            __typename?: "ContactMessageEntity";
            attributes?: {
                __typename?: "ContactMessage";
                name: string;
                email: string;
                phone?: string | null;
                message: string;
                recaptchaResponse: string;
            } | null;
        } | null;
    } | null;
};

export type HomepageQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
}>;

export type HomepageQuery = {
    __typename?: "Query";
    homepage?: {
        __typename?: "HomepageEntityResponse";
        data?: {
            __typename: "HomepageEntity";
            id?: string | null;
            attributes?: {
                __typename?: "Homepage";
                slug?: string | null;
                title?: string | null;
                description?: string | null;
                contact?: {
                    __typename: "ComponentLayoutLink";
                    id: string;
                    label: string;
                    url?: string | null;
                } | null;
                logo?: {
                    __typename?: "UploadFileEntityResponse";
                    data?: {
                        __typename?: "UploadFileEntity";
                        attributes?: {
                            __typename?: "UploadFile";
                            name: string;
                            url: string;
                        } | null;
                    } | null;
                } | null;
                seo?: {
                    __typename?: "ComponentSharedSeo";
                    metaTitle: string;
                    metaDescription: string;
                    metaImage: {
                        __typename?: "UploadFileEntityResponse";
                        data?: {
                            __typename?: "UploadFileEntity";
                            attributes?: {
                                __typename?: "UploadFile";
                                name: string;
                                url: string;
                            } | null;
                        } | null;
                    };
                } | null;
            } | null;
        } | null;
    } | null;
};

export type ImageQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
}>;

export type ImageQuery = {
    __typename?: "Query";
    image?: {
        __typename?: "ImageEntityResponse";
        data?: {
            __typename?: "ImageEntity";
            id?: string | null;
            attributes?: {
                __typename?: "Image";
                slug: string;
                images?: {
                    __typename?: "UploadFileRelationResponseCollection";
                    data: Array<{
                        __typename?: "UploadFileEntity";
                        id?: string | null;
                        attributes?: {
                            __typename?: "UploadFile";
                            name: string;
                            url: string;
                        } | null;
                    }>;
                } | null;
            } | null;
        } | null;
    } | null;
};

export type ServiceQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
}>;

export type ServiceQuery = {
    __typename?: "Query";
    service?: {
        __typename?: "ServiceEntityResponse";
        data?: {
            __typename?: "ServiceEntity";
            id?: string | null;
            attributes?: {
                __typename?: "Service";
                slug?: string | null;
                title?: string | null;
                description?: string | null;
                button?: {
                    __typename?: "ComponentLayoutLink";
                    id: string;
                    label: string;
                    url?: string | null;
                } | null;
                image?: {
                    __typename?: "UploadFileEntityResponse";
                    data?: {
                        __typename?: "UploadFileEntity";
                        attributes?: {
                            __typename?: "UploadFile";
                            name: string;
                            url: string;
                        } | null;
                    } | null;
                } | null;
            } | null;
        } | null;
    } | null;
};

export type SimulatorQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
}>;

export type SimulatorQuery = {
    __typename?: "Query";
    simulator?: {
        __typename?: "SimulatorEntityResponse";
        data?: {
            __typename?: "SimulatorEntity";
            attributes?: {
                __typename?: "Simulator";
                slug?: string | null;
                title?: string | null;
                description?: string | null;
                content?: string | null;
            } | null;
        } | null;
    } | null;
};

export type LayoutImageQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
}>;

export type LayoutImageQuery = {
    __typename?: "Query";
    layoutImage?: {
        __typename?: "LayoutImageEntityResponse";
        data?: {
            __typename: "LayoutImageEntity";
            id?: string | null;
            attributes?: {
                __typename: "LayoutImage";
                slug?: string | null;
                image: {
                    __typename?: "UploadFileEntityResponse";
                    data?: {
                        __typename?: "UploadFileEntity";
                        id?: string | null;
                        attributes?: {
                            __typename?: "UploadFile";
                            name: string;
                            url: string;
                        } | null;
                    } | null;
                };
            } | null;
        } | null;
    } | null;
};

export type GetLocalesQueryVariables = Exact<{ [key: string]: never }>;

export type GetLocalesQuery = {
    __typename?: "Query";
    i18NLocales?: {
        __typename?: "I18NLocaleEntityResponseCollection";
        data: Array<{
            __typename?: "I18NLocaleEntity";
            id?: string | null;
            attributes?: {
                __typename?: "I18NLocale";
                code?: string | null;
            } | null;
        }>;
    } | null;
};

export type MessageQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
}>;

export type MessageQuery = {
    __typename?: "Query";
    message?: {
        __typename?: "MessageEntityResponse";
        data?: {
            __typename?: "MessageEntity";
            attributes?: {
                __typename?: "Message";
                messages?: any | null;
            } | null;
        } | null;
    } | null;
};

export type StaticPagesQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["I18NLocaleCode"]>;
}>;

export type StaticPagesQuery = {
    __typename?: "Query";
    staticPages?: {
        __typename?: "StaticPageEntityResponseCollection";
        data: Array<{
            __typename?: "StaticPageEntity";
            id?: string | null;
            attributes?: {
                __typename?: "StaticPage";
                slug?: string | null;
                title?: string | null;
                content?: string | null;
            } | null;
        }>;
    } | null;
};

export const ErrorPageDocument = gql`
    query ErrorPage($locale: I18NLocaleCode) {
        errorPage(locale: $locale) {
            data {
                attributes {
                    slug
                    title
                    subtitle
                    description
                    button {
                        id
                        label
                        url
                    }
                }
            }
        }
    }
`;

/**
 * __useErrorPageQuery__
 *
 * To run a query within a React component, call `useErrorPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useErrorPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useErrorPageQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useErrorPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ErrorPageQuery,
        ErrorPageQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ErrorPageQuery, ErrorPageQueryVariables>(
        ErrorPageDocument,
        options
    );
}
export function useErrorPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ErrorPageQuery,
        ErrorPageQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ErrorPageQuery, ErrorPageQueryVariables>(
        ErrorPageDocument,
        options
    );
}
export type ErrorPageQueryHookResult = ReturnType<typeof useErrorPageQuery>;
export type ErrorPageLazyQueryHookResult = ReturnType<
    typeof useErrorPageLazyQuery
>;
export type ErrorPageQueryResult = Apollo.QueryResult<
    ErrorPageQuery,
    ErrorPageQueryVariables
>;
export const FooterDocument = gql`
    query Footer($locale: I18NLocaleCode) {
        footer(locale: $locale) {
            data {
                attributes {
                    slug
                    rights
                    link {
                        id
                        label
                        url
                    }
                }
            }
        }
    }
`;

/**
 * __useFooterQuery__
 *
 * To run a query within a React component, call `useFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFooterQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFooterQuery(
    baseOptions?: Apollo.QueryHookOptions<FooterQuery, FooterQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FooterQuery, FooterQueryVariables>(
        FooterDocument,
        options
    );
}
export function useFooterLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FooterQuery, FooterQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FooterQuery, FooterQueryVariables>(
        FooterDocument,
        options
    );
}
export type FooterQueryHookResult = ReturnType<typeof useFooterQuery>;
export type FooterLazyQueryHookResult = ReturnType<typeof useFooterLazyQuery>;
export type FooterQueryResult = Apollo.QueryResult<
    FooterQuery,
    FooterQueryVariables
>;
export const HeaderDocument = gql`
    query Header($locale: I18NLocaleCode) {
        header(locale: $locale) {
            data {
                id
                attributes {
                    slug
                    logo {
                        __typename
                        data {
                            id
                            attributes {
                                name
                                alternativeText
                                url
                                previewUrl
                            }
                        }
                    }
                    menu {
                        __typename
                        id
                        label
                        url
                    }
                    account {
                        __typename
                        id
                        label
                        url
                    }
                    language
                }
            }
        }
    }
`;

/**
 * __useHeaderQuery__
 *
 * To run a query within a React component, call `useHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeaderQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useHeaderQuery(
    baseOptions?: Apollo.QueryHookOptions<HeaderQuery, HeaderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<HeaderQuery, HeaderQueryVariables>(
        HeaderDocument,
        options
    );
}
export function useHeaderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<HeaderQuery, HeaderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<HeaderQuery, HeaderQueryVariables>(
        HeaderDocument,
        options
    );
}
export type HeaderQueryHookResult = ReturnType<typeof useHeaderQuery>;
export type HeaderLazyQueryHookResult = ReturnType<typeof useHeaderLazyQuery>;
export type HeaderQueryResult = Apollo.QueryResult<
    HeaderQuery,
    HeaderQueryVariables
>;
export const AboutDocument = gql`
    query About($locale: I18NLocaleCode) {
        about(locale: $locale) {
            data {
                id
                attributes {
                    slug
                    title
                    description
                    steps {
                        id
                        image {
                            data {
                                id
                                attributes {
                                    name
                                    url
                                }
                            }
                        }
                        title
                        description
                    }
                }
            }
        }
    }
`;

/**
 * __useAboutQuery__
 *
 * To run a query within a React component, call `useAboutQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAboutQuery(
    baseOptions?: Apollo.QueryHookOptions<AboutQuery, AboutQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AboutQuery, AboutQueryVariables>(
        AboutDocument,
        options
    );
}
export function useAboutLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AboutQuery, AboutQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AboutQuery, AboutQueryVariables>(
        AboutDocument,
        options
    );
}
export type AboutQueryHookResult = ReturnType<typeof useAboutQuery>;
export type AboutLazyQueryHookResult = ReturnType<typeof useAboutLazyQuery>;
export type AboutQueryResult = Apollo.QueryResult<
    AboutQuery,
    AboutQueryVariables
>;
export const AppDocument = gql`
    query App($locale: I18NLocaleCode) {
        app(locale: $locale) {
            data {
                attributes {
                    slug
                    title
                    description
                    image {
                        data {
                            attributes {
                                name
                                url
                            }
                        }
                    }
                    button {
                        label
                        url
                    }
                    applinks {
                        title
                        imagelink {
                            id
                            name
                            url
                            image {
                                data {
                                    attributes {
                                        name
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useAppQuery__
 *
 * To run a query within a React component, call `useAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAppQuery(
    baseOptions?: Apollo.QueryHookOptions<AppQuery, AppQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AppQuery, AppQueryVariables>(AppDocument, options);
}
export function useAppLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AppQuery, AppQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AppQuery, AppQueryVariables>(
        AppDocument,
        options
    );
}
export type AppQueryHookResult = ReturnType<typeof useAppQuery>;
export type AppLazyQueryHookResult = ReturnType<typeof useAppLazyQuery>;
export type AppQueryResult = Apollo.QueryResult<AppQuery, AppQueryVariables>;
export const ContactFormDocument = gql`
    query ContactForm($locale: I18NLocaleCode) {
        contactForm(locale: $locale) {
            data {
                id
                attributes {
                    slug
                    title
                    terms
                    button {
                        label
                    }
                    name {
                        label
                    }
                    email {
                        label
                    }
                    phone {
                        label
                    }
                    message {
                        label
                    }
                }
            }
        }
    }
`;

/**
 * __useContactFormQuery__
 *
 * To run a query within a React component, call `useContactFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactFormQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useContactFormQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ContactFormQuery,
        ContactFormQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContactFormQuery, ContactFormQueryVariables>(
        ContactFormDocument,
        options
    );
}
export function useContactFormLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ContactFormQuery,
        ContactFormQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContactFormQuery, ContactFormQueryVariables>(
        ContactFormDocument,
        options
    );
}
export type ContactFormQueryHookResult = ReturnType<typeof useContactFormQuery>;
export type ContactFormLazyQueryHookResult = ReturnType<
    typeof useContactFormLazyQuery
>;
export type ContactFormQueryResult = Apollo.QueryResult<
    ContactFormQuery,
    ContactFormQueryVariables
>;
export const CreateContactMessageDocument = gql`
    mutation CreateContactMessage(
        $name: String
        $email: String
        $phone: String
        $message: String
        $recaptchaResponse: String
    ) {
        createContactMessage(
            data: {
                name: $name
                email: $email
                phone: $phone
                message: $message
                recaptchaResponse: $recaptchaResponse
            }
        ) {
            data {
                attributes {
                    name
                    email
                    phone
                    message
                    recaptchaResponse
                }
            }
        }
    }
`;
export type CreateContactMessageMutationFn = Apollo.MutationFunction<
    CreateContactMessageMutation,
    CreateContactMessageMutationVariables
>;

/**
 * __useCreateContactMessageMutation__
 *
 * To run a mutation, you first call `useCreateContactMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMessageMutation, { data, loading, error }] = useCreateContactMessageMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      message: // value for 'message'
 *      recaptchaResponse: // value for 'recaptchaResponse'
 *   },
 * });
 */
export function useCreateContactMessageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateContactMessageMutation,
        CreateContactMessageMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateContactMessageMutation,
        CreateContactMessageMutationVariables
    >(CreateContactMessageDocument, options);
}
export type CreateContactMessageMutationHookResult = ReturnType<
    typeof useCreateContactMessageMutation
>;
export type CreateContactMessageMutationResult =
    Apollo.MutationResult<CreateContactMessageMutation>;
export type CreateContactMessageMutationOptions = Apollo.BaseMutationOptions<
    CreateContactMessageMutation,
    CreateContactMessageMutationVariables
>;
export const HomepageDocument = gql`
    query Homepage($locale: I18NLocaleCode) {
        homepage(locale: $locale) {
            data {
                __typename
                id
                attributes {
                    slug
                    title
                    description
                    contact {
                        __typename
                        id
                        label
                        url
                    }
                    logo {
                        data {
                            attributes {
                                name
                                url
                            }
                        }
                    }
                    seo {
                        metaTitle
                        metaDescription
                        metaImage {
                            data {
                                attributes {
                                    name
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useHomepageQuery__
 *
 * To run a query within a React component, call `useHomepageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomepageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomepageQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useHomepageQuery(
    baseOptions?: Apollo.QueryHookOptions<HomepageQuery, HomepageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<HomepageQuery, HomepageQueryVariables>(
        HomepageDocument,
        options
    );
}
export function useHomepageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        HomepageQuery,
        HomepageQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<HomepageQuery, HomepageQueryVariables>(
        HomepageDocument,
        options
    );
}
export type HomepageQueryHookResult = ReturnType<typeof useHomepageQuery>;
export type HomepageLazyQueryHookResult = ReturnType<
    typeof useHomepageLazyQuery
>;
export type HomepageQueryResult = Apollo.QueryResult<
    HomepageQuery,
    HomepageQueryVariables
>;
export const ImageDocument = gql`
    query Image($locale: I18NLocaleCode) {
        image(locale: $locale) {
            data {
                id
                attributes {
                    slug
                    images {
                        data {
                            id
                            attributes {
                                name
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useImageQuery__
 *
 * To run a query within a React component, call `useImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useImageQuery(
    baseOptions?: Apollo.QueryHookOptions<ImageQuery, ImageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ImageQuery, ImageQueryVariables>(
        ImageDocument,
        options
    );
}
export function useImageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ImageQuery, ImageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ImageQuery, ImageQueryVariables>(
        ImageDocument,
        options
    );
}
export type ImageQueryHookResult = ReturnType<typeof useImageQuery>;
export type ImageLazyQueryHookResult = ReturnType<typeof useImageLazyQuery>;
export type ImageQueryResult = Apollo.QueryResult<
    ImageQuery,
    ImageQueryVariables
>;
export const ServiceDocument = gql`
    query Service($locale: I18NLocaleCode) {
        service(locale: $locale) {
            data {
                id
                attributes {
                    slug
                    title
                    description
                    button {
                        id
                        label
                        url
                    }
                    image {
                        data {
                            attributes {
                                name
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useServiceQuery__
 *
 * To run a query within a React component, call `useServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useServiceQuery(
    baseOptions?: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(
        ServiceDocument,
        options
    );
}
export function useServiceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ServiceQuery,
        ServiceQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ServiceQuery, ServiceQueryVariables>(
        ServiceDocument,
        options
    );
}
export type ServiceQueryHookResult = ReturnType<typeof useServiceQuery>;
export type ServiceLazyQueryHookResult = ReturnType<typeof useServiceLazyQuery>;
export type ServiceQueryResult = Apollo.QueryResult<
    ServiceQuery,
    ServiceQueryVariables
>;
export const SimulatorDocument = gql`
    query Simulator($locale: I18NLocaleCode) {
        simulator(locale: $locale) {
            data {
                attributes {
                    slug
                    title
                    description
                    content
                }
            }
        }
    }
`;

/**
 * __useSimulatorQuery__
 *
 * To run a query within a React component, call `useSimulatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulatorQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSimulatorQuery(
    baseOptions?: Apollo.QueryHookOptions<
        SimulatorQuery,
        SimulatorQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SimulatorQuery, SimulatorQueryVariables>(
        SimulatorDocument,
        options
    );
}
export function useSimulatorLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SimulatorQuery,
        SimulatorQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SimulatorQuery, SimulatorQueryVariables>(
        SimulatorDocument,
        options
    );
}
export type SimulatorQueryHookResult = ReturnType<typeof useSimulatorQuery>;
export type SimulatorLazyQueryHookResult = ReturnType<
    typeof useSimulatorLazyQuery
>;
export type SimulatorQueryResult = Apollo.QueryResult<
    SimulatorQuery,
    SimulatorQueryVariables
>;
export const LayoutImageDocument = gql`
    query LayoutImage($locale: I18NLocaleCode) {
        layoutImage(locale: $locale) {
            data {
                __typename
                id
                attributes {
                    __typename
                    slug
                    image {
                        data {
                            id
                            attributes {
                                name
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useLayoutImageQuery__
 *
 * To run a query within a React component, call `useLayoutImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayoutImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayoutImageQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useLayoutImageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        LayoutImageQuery,
        LayoutImageQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<LayoutImageQuery, LayoutImageQueryVariables>(
        LayoutImageDocument,
        options
    );
}
export function useLayoutImageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        LayoutImageQuery,
        LayoutImageQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<LayoutImageQuery, LayoutImageQueryVariables>(
        LayoutImageDocument,
        options
    );
}
export type LayoutImageQueryHookResult = ReturnType<typeof useLayoutImageQuery>;
export type LayoutImageLazyQueryHookResult = ReturnType<
    typeof useLayoutImageLazyQuery
>;
export type LayoutImageQueryResult = Apollo.QueryResult<
    LayoutImageQuery,
    LayoutImageQueryVariables
>;
export const GetLocalesDocument = gql`
    query GetLocales {
        i18NLocales {
            data {
                id
                attributes {
                    code
                }
            }
        }
    }
`;

/**
 * __useGetLocalesQuery__
 *
 * To run a query within a React component, call `useGetLocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocalesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetLocalesQuery,
        GetLocalesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetLocalesQuery, GetLocalesQueryVariables>(
        GetLocalesDocument,
        options
    );
}
export function useGetLocalesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetLocalesQuery,
        GetLocalesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetLocalesQuery, GetLocalesQueryVariables>(
        GetLocalesDocument,
        options
    );
}
export type GetLocalesQueryHookResult = ReturnType<typeof useGetLocalesQuery>;
export type GetLocalesLazyQueryHookResult = ReturnType<
    typeof useGetLocalesLazyQuery
>;
export type GetLocalesQueryResult = Apollo.QueryResult<
    GetLocalesQuery,
    GetLocalesQueryVariables
>;
export const MessageDocument = gql`
    query Message($locale: I18NLocaleCode) {
        message(locale: $locale) {
            data {
                attributes {
                    messages
                }
            }
        }
    }
`;

/**
 * __useMessageQuery__
 *
 * To run a query within a React component, call `useMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useMessageQuery(
    baseOptions?: Apollo.QueryHookOptions<MessageQuery, MessageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MessageQuery, MessageQueryVariables>(
        MessageDocument,
        options
    );
}
export function useMessageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        MessageQuery,
        MessageQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MessageQuery, MessageQueryVariables>(
        MessageDocument,
        options
    );
}
export type MessageQueryHookResult = ReturnType<typeof useMessageQuery>;
export type MessageLazyQueryHookResult = ReturnType<typeof useMessageLazyQuery>;
export type MessageQueryResult = Apollo.QueryResult<
    MessageQuery,
    MessageQueryVariables
>;
export const StaticPagesDocument = gql`
    query StaticPages($locale: I18NLocaleCode) {
        staticPages(locale: $locale) {
            data {
                id
                attributes {
                    slug
                    title
                    content
                }
            }
        }
    }
`;

/**
 * __useStaticPagesQuery__
 *
 * To run a query within a React component, call `useStaticPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticPagesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useStaticPagesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        StaticPagesQuery,
        StaticPagesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<StaticPagesQuery, StaticPagesQueryVariables>(
        StaticPagesDocument,
        options
    );
}
export function useStaticPagesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        StaticPagesQuery,
        StaticPagesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<StaticPagesQuery, StaticPagesQueryVariables>(
        StaticPagesDocument,
        options
    );
}
export type StaticPagesQueryHookResult = ReturnType<typeof useStaticPagesQuery>;
export type StaticPagesLazyQueryHookResult = ReturnType<
    typeof useStaticPagesLazyQuery
>;
export type StaticPagesQueryResult = Apollo.QueryResult<
    StaticPagesQuery,
    StaticPagesQueryVariables
>;
