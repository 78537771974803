import { motion } from "framer-motion";
import useWindowSize from "../../../hooks/useWindowSize";
import { isWindowDesktop } from "../../../lib/abstract/windowEvents";
import { useHeaderModal } from "../../../contexts/ModalProvider";

export interface MenuItemProps {
    className?: string;
    label?: string;
    url?: string;
}

export const menuItemVariants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 },
        },
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 },
        },
    },
};

export const MenuItem = ({ label, url, className }: MenuItemProps) => {
    const { width } = useWindowSize();
    const { closeHeader } = useHeaderModal();
    return (
        <>
            {width && (
                <motion.li
                    className={className}
                    variants={
                        isWindowDesktop(width) ? undefined : menuItemVariants
                    }
                >
                    <motion.span className="inline-block">
                        {url ? (
                            <a onClick={closeHeader} href={url}>
                                {label}
                            </a>
                        ) : (
                            label
                        )}
                    </motion.span>
                </motion.li>
            )}
        </>
    );
};
