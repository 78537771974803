import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { PropsWithChildren } from "react";

const GRAPHQL_ENDPOINT = `${process.env.REACT_APP_BACKEND}/graphql`;


const StrapiApolloProvider: React.FC<PropsWithChildren<{}>> = ({
    children,
}) => {
    const client = new ApolloClient({
        uri: GRAPHQL_ENDPOINT,
        cache: new InMemoryCache(),
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default StrapiApolloProvider;
