import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "../../contexts/CookiesProvider";
import { useIndexeDb } from "../../contexts/IndexedDdProvider";
import { useLocale } from "../../contexts/LocaleProvider";
import { useMessageQuery } from "../../generated";
import { updateSingleTypeData } from "../../lib/indexed_db/indexed_db";
import Modal from "../Modal/Modal";
import Toggle from "../Toggle/Toggle";

export default function CookiesModal() {
    const {
        cookieObject,
        setCookieObject,
        isCookiesModalOpen,
        setIsCookiesModalOpen,
    } = useCookies();
    const { locale } = useLocale();
    const { store } = useIndexeDb();
    const [isToggled, setIsToggled] = useState(cookieObject?.analytics ?? false);
    const { data, error } = useMessageQuery({ variables: { locale: locale } });
    const [dataToUse, setDataToUse] = useState<any>();

    if (error) {
        console.log("error: ", error);
    }

    const modal = data?.message?.data?.attributes?.messages?.["cookies-modal"];

    const handleClickDiv = (event: any) => {
        event.stopPropagation();
    };

    // const handleAcceptButton = () => {
    //   setCookieObject({
    //     ...cookieObject,
    //     consent: true,
    //     analytics: true,
    //   });
    // };

    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setCookieObject({
            ...cookieObject,
            essentials: true,
            analytics: isToggled,
        });
        setIsCookiesModalOpen(false);
        if (window) {
            window.location.reload();
        }
    };

    useEffect(() => {
        updateSingleTypeData(data, modal, "cookiesModal", store, setDataToUse);
        // eslint-disable-next-line
    }, [data, store]);

    return (
        <Modal isOpen={isCookiesModalOpen} onClose={setIsCookiesModalOpen}>
            <div
                className="max-w-6xl"
                onClick={(event) => handleClickDiv(event)}
            >
                <div className="grid cookies-modal-info">
                    <header className="grid gap-4 p-6">
                        <h4 className="font-bold text-lg">
                            {dataToUse?.title}
                        </h4>
                        <p className="text-mycarflix-gray">
                            {dataToUse?.description}
                        </p>
                    </header>

                    <form
                        className="grid"
                        onSubmit={(event) => handleOnSubmit(event)}
                    >
                        {dataToUse?.cookies.map(
                            (cookie: any, index: number) => (
                                <article
                                    key={cookie?.slug ?? index}
                                    className="grid cookies-modal-info-article gap-x-6 gap-y-4 lg:gap-16 mx-6 py-6 border-t border-opacity-5 border-mycarflix-gray items-center"
                                >
                                    <h5 className="font-bold title capitalize">
                                        {cookie?.title}
                                    </h5>
                                    <p className="text-mycarflix-gray description">
                                        {cookie?.description}
                                    </p>

                                    {cookie?.status ? (
                                        <button className="button">
                                            <strong> {cookie?.status} </strong>{" "}
                                        </button>
                                    ) : (
                                        <Toggle
                                            className="button"
                                            id="analytics"
                                            label=""
                                            checked={isToggled}
                                            onChange={() =>
                                                setIsToggled(
                                                    !isToggled
                                                )
                                            }
                                        />
                                    )}
                                </article>
                            )
                        )}

                        <section className="bg-gray-100 p-4 rounded-b-4xl flex justify-between items-center">
                            {/* <button
              className="bg-blue-300 rounded-full py-4 px-8"
              onClick={() => handleAcceptButton()}
              >
                Aceitar
              </button> */}
                            <button
                                type="submit"
                                className="bg-mycarflix-blue text-white hover:bg-mycarflix-section-bg duration-200 rounded-full py-4 px-8"
                            >
                                {dataToUse?.savebutton}
                            </button>
                            <Link
                                to={dataToUse?.cookiespagelink?.url}
                                className="text-mycarflix-gray font-bold"
                                onClick={() => setIsCookiesModalOpen(false)}
                            >
                                {dataToUse?.cookiespagelink?.label}
                            </Link>
                        </section>
                    </form>
                </div>
            </div>
        </Modal>
    );
}
