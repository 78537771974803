import { motion } from "framer-motion";
import { childVariants } from "../../../lib/animations/leftToRight";

interface ImageProps {
    url: string;
    name: string;
}
export interface ImageLinkProps {
    image: ImageProps | undefined;
    link: string;
}
export default function ImageLink({ image, link }: ImageLinkProps) {
    return (
        <motion.a className="inline-block" variants={childVariants} href={link}>
            <img src={image?.url ?? ""} alt={image?.name ?? ""} />
        </motion.a>
    );
}
