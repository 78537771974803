import { motion } from "framer-motion";
import useWindowSize from "../../../hooks/useWindowSize";
import { isWindowDesktop } from "../../../lib/abstract/windowEvents";

export interface MenuItemProps {
    className?: string;
    label?: string;
    url?: string;
}

export const menuItemVariants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 },
        },
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 },
        },
    },
};

export const MenuItemLink = ({ label, url, className }: MenuItemProps) => {
    const { width } = useWindowSize();
    return (
        <>
            {width && (
                <motion.li
                    className={className}
                    variants={
                        isWindowDesktop(width) ? undefined : menuItemVariants
                    }
                >
                    <a className="px-7 py-3 inline-block w-40" href={url}>
                        <motion.span className="inline-block">
                            {label}
                        </motion.span>
                    </a>
                </motion.li>
            )}
        </>
    );
};
