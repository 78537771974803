import About from "./About/About";
import ContactForm from "./ContactForm/ContactForm";
import Hero from "./Hero/Hero";
import ImageSection from "./ImageSection/ImageSection";
import MobileApps from "./MobileApps/MobileApps";
import Service from "./Service/Service";

export default function Homepage() {
    return (
        <main className="flex justify-center">
            <div className="px-8 max-w-7xl">
                <Hero />
                <About />
                <Service />
                {/* <Simulator /> */}
                <ImageSection />
                <MobileApps />
                <ContactForm />
            </div>
        </main>
    );
}
