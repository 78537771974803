import { ReactNode, useEffect, useRef, useState } from "react";
import Header, { LinkProps } from "../Header/Header";
import { motion } from "framer-motion";
import { Navigation } from "../Header/Navigation/Navigation";
import { useDimensions } from "../../lib/use_dimensions";
import { useHeaderModal } from "../../contexts/ModalProvider";
import useWindowSize from "../../hooks/useWindowSize";
import Footer from "../Footer/Footer";
import { useLocale } from "../../contexts/LocaleProvider";
import { useHeaderQuery, useLayoutImageQuery } from "../../generated";
import Loading from "../Loading/Loading";
import { useIndexeDb } from "../../contexts/IndexedDdProvider";
import { updateSingleTypeData } from "../../lib/indexed_db/indexed_db";

interface LayoutProps {
    children: ReactNode;
}

export default function Layout({ children }: LayoutProps) {
    const containerRef = useRef(null);
    const { width } = useWindowSize();
    const { height } = useDimensions(containerRef);
    const { isHeaderOpen } = useHeaderModal();

    const { locale } = useLocale();
    const { data, error } = useLayoutImageQuery({
        variables: { locale: locale },
    });
    const { data: headerData, error: errorHeader } = useHeaderQuery({
        variables: {
            locale: locale,
        },
    });

    if (error) {
        console.log("error: ", error);
    }
    if (errorHeader) {
        console.log("Error: ", errorHeader);
    }
    const { store } = useIndexeDb();
    const [dataToUseInLayout, setDataToUseInLayout] = useState<any>();
    const [dataToUseInHeader, setDataToUseInHeader] = useState<any>();
    const header: any = headerData?.header?.data?.attributes;

    const layoutImage = data?.layoutImage?.data?.attributes;
    const imageUrl = dataToUseInLayout?.image.data?.attributes.url ?? "";
    const menu: Array<LinkProps> = dataToUseInHeader?.menu;
    const account: Array<LinkProps> = dataToUseInHeader?.account;
    const language: string = dataToUseInHeader?.language;

    const sidebar = {
        open: (height = 1000) => ({
            clipPath: `circle(${height * 2 + 200}px at calc(100% - 25px) 40px)`,
            transition: {
                type: "spring",
                stiffness: 20,
                restDelta: 2,
            },
        }),
        closed: {
            clipPath: "circle(0px at calc(100% + 10px) -10px)",
            transition: {
                delay: 0.1,
                type: "spring",
                stiffness: 400,
                damping: 40,
            },
        },
    };

    useEffect(() => {
        updateSingleTypeData(
            data,
            layoutImage,
            "layoutImage",
            store,
            setDataToUseInLayout
        );
        updateSingleTypeData(
            headerData,
            header,
            "header",
            store,
            setDataToUseInHeader
        );
        // eslint-disable-next-line
    }, [data, headerData, store]);

    return (
        <>
            {!dataToUseInHeader && !dataToUseInLayout && <Loading />}
            <>
                <Header />
                <motion.div
                    animate={isHeaderOpen ? "open" : "closed"}
                    className={`${
                        width ? "block" : "hidden"
                    } fixed bg-mycarflix-blue right-0 h-full w-full duration-200 text-white lg:hidden z-10`}
                    variants={sidebar}
                >
                    <motion.nav
                        className="mx-6 pb-9 flex flex-col justify-between"
                        animate={isHeaderOpen ? "open" : "closed"}
                        custom={height}
                        ref={containerRef}
                    >
                        <Navigation
                            menu={menu}
                            account={account}
                            language={language}
                            className="flex flex-col gap-5 header-list items-center text-lg"
                        />
                    </motion.nav>
                </motion.div>
                <div
                    className="bg-cover bg-no-repeat layout-image"
                    style={{ backgroundImage: `url(${imageUrl})` }}
                >
                    {children}
                    <Footer />
                </div>
            </>
        </>
    );
}
