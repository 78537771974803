import DOMPurify from "dompurify";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useLocale } from "../../../contexts/LocaleProvider";
import {
    useContactFormQuery,
    useCreateContactMessageMutation,
    useMessageQuery,
} from "../../../generated";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import Swal from "sweetalert2";
import Loading from "../../Loading/Loading";
import { useIndexeDb } from "../../../contexts/IndexedDdProvider";
import { updateSingleTypeData } from "../../../lib/indexed_db/indexed_db";
import LoadingDots from "../../Loading/LoadingDots";

export default function ContactForm() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
        recaptchaResponse: "",
    });

    const [token, setToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [isSendingContact, setIsSendingContact] = useState(false);

    const { locale } = useLocale();
    const { data, error } = useContactFormQuery({
        variables: {
            locale: locale,
        },
    });

    const { data: messages, error: messagesError } = useMessageQuery({
        variables: { locale: locale },
    });

    const { store } = useIndexeDb();
    const successMessage =
        messages?.message?.data?.attributes?.messages?.[
            "contact-message-success"
        ];
    const errorMessage =
        messages?.message?.data?.attributes?.messages?.[
            "contact-message-error"
        ];
    const contactForm = data?.contactForm?.data?.attributes;
    const [successMessageToUse, setSuccessMessageToUse] = useState<any>();
    const [errorMessageToUse, setErrorMessageToUse] = useState<any>();
    const [dataToUse, setDataToUse] = useState<any>();
    const [CreateContactMessage] = useCreateContactMessageMutation();

    const slug = dataToUse?.slug;
    const name = dataToUse?.name?.label;
    const title = dataToUse?.title;
    const button = dataToUse?.button?.label;
    const email = dataToUse?.email?.label;
    const phone = dataToUse?.phone?.label;
    const message = dataToUse?.message?.label;
    const terms = dataToUse?.terms;

    if (error) {
        console.log("error: ", error);
    }

    if (messagesError) {
        console.log("error: ", messagesError);
    }

    const onVerify = useCallback(
        (token: any) => {
            setToken(token);
        },
        [setToken]
    );

    const CleanedTerms = () => ({
        __html: DOMPurify.sanitize(terms ?? ""),
    });

    const handleInputChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSendingContact(true);
        CreateContactMessage({
            variables: {
                name: { ...formData }.name,
                email: { ...formData }.email,
                phone: { ...formData }.phone,
                message: { ...formData }.message,
                recaptchaResponse: token,
            },
        })
            .then(() => {
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    message: "",
                    recaptchaResponse: "",
                });

                setRefreshReCaptcha((r) => !r);
                setIsSendingContact(false);
                Swal.fire({
                    title: successMessageToUse?.title,
                    text: successMessageToUse?.description,
                    icon: "success",
                    confirmButtonText: successMessageToUse?.button,
                    iconColor: "#44A9DE",
                    confirmButtonColor: "#44A9DE",
                });
            })
            .catch((error: Error) => {
                setIsSendingContact(false);
                Swal.fire({
                    title: errorMessageToUse?.title,
                    text: errorMessageToUse?.description,
                    icon: "error",
                    confirmButtonText: errorMessageToUse?.button,
                    iconColor: "red",
                    confirmButtonColor: "red",
                });
                console.error(error);
            });
    };

    useEffect(() => {
        updateSingleTypeData(
            data,
            contactForm,
            "contactForm",
            store,
            setDataToUse
        );
        updateSingleTypeData(
            messages,
            successMessage,
            "contactMessageSuccess",
            store,
            setSuccessMessageToUse
        );
        updateSingleTypeData(
            messages,
            errorMessage,
            "contactMessageError",
            store,
            setErrorMessageToUse
        );
        // eslint-disable-next-line
    }, [data, messages, store]);

    return (
        <>
            {!dataToUse && <Loading />}
            <section id={slug ?? ""} className="sm:w-4/6 lg:w-1/2 m-auto mt-24">
                <form
                    id="contact-form"
                    onSubmit={handleSubmit}
                    className="grid gap-5"
                >
                    <h2 className="text-base lg:text-2xl font-bold">{title}</h2>
                    <input
                        className="py-3 px-5 rounded-full border-2 contact-form-input border-mycaflix-input-bg"
                        id="name"
                        type="text"
                        name="name"
                        placeholder={name ?? ""}
                        required
                        value={DOMPurify.sanitize(formData.name)}
                        onChange={(event) => handleInputChange(event)}
                    />
                    <div className="grid lg:grid-cols-2 gap-5">
                        <input
                            className="py-3 px-5 rounded-full border-2 contact-form-input border-mycaflix-input-bg"
                            id="email"
                            type="email"
                            name="email"
                            placeholder={email ?? ""}
                            required
                            value={DOMPurify.sanitize(formData.email)}
                            onChange={(event) => handleInputChange(event)}
                        />
                        <input
                            className="py-3 px-5 rounded-full border-2 contact-form-input border-mycaflix-input-bg"
                            id="phone"
                            type="text"
                            name="phone"
                            placeholder={phone ?? ""}
                            value={DOMPurify.sanitize(formData.phone)}
                            onChange={(event) => handleInputChange(event)}
                        />
                    </div>
                    <textarea
                        className="py-3 px-5 rounded-3xl resize-none border-2 contact-form-input border-mycaflix-input-bg"
                        id="message"
                        name="message"
                        placeholder={message ?? ""}
                        required
                        value={DOMPurify.sanitize(formData.message)}
                        onChange={(event) => handleInputChange(event)}
                        rows={5}
                    />
                    <GoogleReCaptcha
                        onVerify={onVerify}
                        refreshReCaptcha={refreshReCaptcha}
                    />
                    <p
                        className="terms-and-policy-paragraph"
                        dangerouslySetInnerHTML={CleanedTerms()}
                    />
                    <button
                        className={`g-recaptcha button-animation bg-mycarflix-black-bg text-white hover:bg-black ease-in-out px-12 py-4 rounded-full inline-block font-bold text-base lg:text-lg `}
                        type="submit"
                    >
                        {isSendingContact ? <LoadingDots /> : button}
                    </button>
                </form>
            </section>
        </>
    );
}
