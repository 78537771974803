import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import StrapiApolloProvider from "./graphql/apollo";
import { LocaleProvider } from "./contexts/LocaleProvider";
import { ModalProvider } from "./contexts/ModalProvider";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { IndexedDbProvider } from "./contexts/IndexedDdProvider";
import { CookiesProvider } from "./contexts/CookiesProvider";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <GoogleReCaptchaProvider
            reCaptchaKey={`${process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}`}
            scriptProps={{
                appendTo: "body",
            }}
        >
            <StrapiApolloProvider>
                <ModalProvider>
                    <LocaleProvider>
                        <IndexedDbProvider>
                            <CookiesProvider>
                                <App />
                            </CookiesProvider>
                        </IndexedDbProvider>
                    </LocaleProvider>
                </ModalProvider>
            </StrapiApolloProvider>
        </GoogleReCaptchaProvider>
    </React.StrictMode>
);
