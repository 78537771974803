import { useInView } from "framer-motion";
import { useLocale } from "../../../contexts/LocaleProvider";
import { useServiceQuery } from "../../../generated";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import {
    childVariants,
    containerVariants,
} from "../../../lib/animations/leftToRight";
import Loading from "../../Loading/Loading";
import { useIndexeDb } from "../../../contexts/IndexedDdProvider";
import { updateSingleTypeData } from "../../../lib/indexed_db/indexed_db";

export default function Service() {
    const { locale } = useLocale();
    const { data, error } = useServiceQuery({
        variables: {
            locale: locale,
        },
    });

    const container = useRef(null);
    const isInView = useInView(container, { once: true, amount: "all" });

    const { store } = useIndexeDb();
    const service = data?.service?.data?.attributes;
    const [dataToUse, setDataToUse] = useState<any>();

    if (error) {
        console.log("error: ", error);
    }

    useEffect(() => {
        updateSingleTypeData(
            data,
            service,
            "serviceSection",
            store,
            setDataToUse
        );
        // eslint-disable-next-line
    }, [data, store]);

    const slug = dataToUse?.slug;
    const title = dataToUse?.title;
    const description = dataToUse?.description;
    const button = dataToUse?.button;
    const image = dataToUse?.image;

    return (
        <>
            {!dataToUse && <Loading />}
            <section
                id={slug ?? ""}
                className="grid lg:grid-cols-2 gap-9 min-h-screen items-center mt-52 sm:w-4/6 lg:w-auto m-auto text-center lg:text-left"
            >
                <motion.div
                    variants={containerVariants}
                    ref={container}
                    initial={"hidden"}
                    animate={isInView ? "visible" : ""}
                    className="grid justify-items-center"
                >
                    <motion.h2
                        variants={childVariants}
                        className="text-4xl lg:text-6xl font-bold"
                    >
                        {title}
                    </motion.h2>
                    <motion.p
                        variants={childVariants}
                        className="text-mycarflix-gray lg:text-xl mt-9"
                    >
                        {description}
                    </motion.p>
                    <motion.a
                        variants={childVariants}
                        className="button-animation bg-mycarflix-black-bg text-white hover:bg-black ease-in-out px-12 py-4 rounded-full inline-block mt-10 font-bold text-xs md:text-base lg:text-lg whitespace-nowrap lg:justify-self-start"
                        href={button?.url ?? ""}
                    >
                        {button?.label}
                    </motion.a>
                </motion.div>
                <div className="">
                    <img
                        src={image?.data?.attributes?.url}
                        alt={image?.data?.attributes?.name}
                    />
                </div>
            </section>
        </>
    );
}
