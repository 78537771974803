import ReactDOM from "react-dom";

export default function Portal({ children }: any) {
    const portalRoot = document.getElementById("portal-root");

    if (!portalRoot) {
        throw new Error("Portal root element not found");
    }

    return portalRoot ? ReactDOM.createPortal(children, portalRoot) : null;
}
