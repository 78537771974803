export const firebaseConfig = {
    apiKey: "AIzaSyAN_w4FTZXi4YVSgTTxVJi44uRXZuUX0_g",
    authDomain: "mycarflix-58ff7.firebaseapp.com",
    databaseURL: "https://mycarflix-58ff7.firebaseio.com",
    projectId: "mycarflix-58ff7",
    storageBucket: "mycarflix-58ff7.appspot.com",
    messagingSenderId: "688395075843",
    appId: "1:688395075843:web:56338ce9f2759452502936",
    measurementId: "G-WT98C3E55X",
}; // Initialize Firebase
