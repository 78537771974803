import { getMobileOS } from "../../../lib/homepage/mobileapps";
import ImageLink from "./ImageLink";

export default function MobileAppLink({ appLinksImages }: any) {
    const getMobileApps = () => {
        if (getMobileOS() === "android") {
            let androidImage: any = appLinksImages?.filter(
                (appLinkImage: any) => {
                    return appLinkImage?.name === "android";
                }
            );

            return androidImage?.map((androidImage: any) => {
                return (
                    <ImageLink
                        key={androidImage?.id}
                        image={
                            androidImage?.image?.data?.attributes ?? undefined
                        }
                        link={androidImage?.url ?? ""}
                    />
                );
            });
        } else if (getMobileOS() === "ios") {
            let iosImage: any = appLinksImages?.filter((appLinkImage: any) => {
                return appLinkImage?.name === "ios";
            });

            return iosImage?.map((iosImage: any) => {
                return (
                    <ImageLink
                        key={iosImage?.id}
                        image={iosImage?.image?.data?.attributes ?? undefined}
                        link={iosImage?.url ?? ""}
                    />
                );
            });
        } else {
            return appLinksImages?.map((appLinkImage: any) => (
                <ImageLink
                    key={appLinkImage?.id}
                    image={appLinkImage?.image?.data?.attributes ?? undefined}
                    link={appLinkImage?.url ?? ""}
                />
            ));
        }
    };

    return <>{getMobileApps()}</>;
}
