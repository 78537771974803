import { createContext, ReactNode, useContext } from "react";
import Dexie from "dexie";
import { useLocale } from "./LocaleProvider";

interface IndexedDbProps {
    store: Dexie;
}

interface IndexedDbProviderProps {
    children: ReactNode;
}

const Database = createContext<IndexedDbProps>({} as IndexedDbProps);

export const IndexedDbProvider = ({ children }: IndexedDbProviderProps) => {
    const { locale } = useLocale();

    const ptDB = new Dexie("myCarflix_pt");
    const enDB = new Dexie("myCarflix_en");

    const store = locale === "pt" ? ptDB : enDB;

    const INDEXEDDB_TABLES = {
        heroSection: "slug, title, description, contact, logo, seo",
        aboutSection: "slug, title, description, steps",
        StaticPage: "id , attributes",
        header: "slug, logo, menu, account, language",
        layoutImage: "slug, image",
        footer: "slug, rights, link",
        errorPage: "slug, title, substitle, escription,button",
        contactForm: "slug, title, terms, button, name, email, phone, message",
        appSection: "slug, title, description, image, button, applinks",
        serviceSection: "slug, title, description, button, image",
        simulatorSection: "slug, title, description, content",
        contactMessageSuccess: "slug, title, description, button",
        contactMessageError: "slug, title, description, button",
        cookiesPopUp: "slug, title, description, modalbutton, acceptbutton",
        cookiesModal:
            "slug, title, desription, cookies, savebutton, cookiespagelink",
        imageSection: "slug, attributes",
    };

    store.version(0.4).stores(INDEXEDDB_TABLES);

    return (
        <Database.Provider
            value={{
                store,
            }}
        >
            {children}
        </Database.Provider>
    );
};

export function useIndexeDb(): IndexedDbProps {
    const context = useContext(Database);
    return context;
}
